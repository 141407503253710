import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { useForm, Controller } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GET_PRICING_HERO } from "ApplicationURI";
import { ADD_PRICING_HERO } from "ApplicationURI";
import { EDIT_PRICING_HERO } from "ApplicationURI";
import { DELETE_PRICING_HERO } from "ApplicationURI";
import { GET_PRICING_CONTENT } from "ApplicationURI";
import { ADD_PRICING_CONTENT } from "ApplicationURI";
import { DELETE_PRICING_CONTENT } from "ApplicationURI";
import { EDIT_PRICING_CONTENT } from "ApplicationURI";
import JoditEditor from "jodit-react";
import { GET_PRICING_HERO_BY_ID } from "ApplicationURI";


function Pricing() {
  const [response,setResponse] = useState([])
  const [priceContentResponse,setPriceContentResponse] = useState([])
  const [open, setOpen] = useState(false);
  const [pricingOpen,setPricingOpen]= useState(false);
  

  const { control, handleSubmit,reset, formState: { errors }} = useForm( );
  const [errorMessage, setErrorMessage] = useState('');
const [openSnackbar, setOpenSnackbar] = useState(false);
const [updateRes,setUpdateRes] = useState([])
const selectedRow = updateRes;
const [selectedRowId, setSelectedRowId] = useState(null);

const[editTopic,setEditTopic]=useState('');
const [editamount,setEditAmount] = useState('');
const [editButton,setEditButton] = useState('');
const [content, setContent] = useState('');
const [editorres,setEditorres]=useState('');
const striptags = require('striptags');


const config = {
  readonly: false,
  toolbarButtonSize: 'middle',
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
   toolbarAdaptive: false,
  toolbarSticky: false,
  hidePoweredByJodit: true,
    removeButtons: ['hr', 'source','video','print','preview','about','copyformat',],
  limitWords:false,
       toolbarStickyOffset: 0,
  toolbar: [
     
      'underline',
      'strikethrough',
      'eraser',
      '|',
      'ul',
      'ol',
      '|',
      'outdent',
      'indent',
      '|',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
      'undo',
      'redo',
      '|',
      'cut',
      'hr',
      'symbol',
      'selectall',
      'copyformat',
      '|',
      'print',
      'preview',
      'about',
  ]
};


const PricingHeroById =(id) =>{
  axios.get(`${BASE_URL}${GET_PRICING_HERO_BY_ID}${id}`)
  .then((res) =>{
    console.log("iddd"+selectedRow)
    setUpdateRes(res.data.id);
    console.log("step 1" + res.data.data);
    console.log("step 2" + JSON.stringify(res.data));
    console.log("step 3" + res);
    setEditTopic(res.data.topic);
    setEditButton(res.data.buttonUrl);
    setEditorres(res.data.description);
    setEditAmount(res.data.amount);
  })
  .catch((e) => {
    // handle error
  });
};

const handleEdit = (selectedRow) => {
  console.log('selectedRow:', selectedRow); // check the value of selectedRow
  console.log('selectedRow.id:', selectedRow.id); // check the value of selectedRow.id
  setSelectedRowId(selectedRow.original.id);
  PricingHeroById(selectedRow.original.id);
};

//pricing hero
  function handleDelete(data) {
    window.alert('Are you sure you want to delete this Pricing?');
    console.log(data.original.id);
    console.log("this is delete inside");
    request({url:`${DELETE_PRICING_HERO}${data.original.id}`, method: "delete"})
    .then(res=>{
      console.log(res);
      PricingList();
    }).catch(error=>{
console.log(error);
    });
    }

    //pricing content
    function handlePriceDelete(data) {
      window.alert('Are you sure you want to delete this Pricing Content?');
      console.log(data.original.id);
      console.log("this is delete inside");
      request({url:`${DELETE_PRICING_CONTENT}${data.original.id}`, method: "delete"})
      .then(res=>{
        console.log(res);
        PricingContentList();
      }).catch(error=>{
  console.log(error);
      });
      }

    //pricing hero

   
  
  

  const handleEdits = (e) => {
    

    console.log("Button clicked  in editttt");
    console.log(selectedRow+"selected");

    const emptyFields = [];
    // console.log(JSON.stringify(row)  +"testttttt")
    if (!editorres) {
      emptyFields.push('Description');
    }
  
    if (!editTopic) {
      emptyFields.push('Topic');
    }
    if (!editButton) {
      emptyFields.push('Button Url');
    }
    if (!editamount) {
      emptyFields.push('Amount');
    }
   
   
  
    // Check if any fields are empty
    if (emptyFields.length > 0) {
      const error = `Fields are required: ${emptyFields.join(', ')}`;
      alert(error);
      // You can display the error message or handle it as needed
      return;
    }
    
    if (editTopic.length < 2) {
      alert('Topic should contain at least 2 characters');
      return;
    }
  

    if (editTopic.length > 250) {
      alert("Topic can't exceed 250 characters");
      return;
    }

    const amount = Number(editamount);

    if (isNaN(amount) || amount < 0.01 || amount > 999999) {
      alert('Amount should be a number between 1 and 999,999'); // Adjust the minimum value for decimals
      return;
    }
      if(editorres && editorres.length > 5000) {
        alert('Description should not exceed 5000 characters');
        return;
    }
   

    const data={
      topic:editTopic,
      description:editorres,
      buttonUrl:editButton,
      amount:Number(editamount)
    }
     request({url:`${EDIT_PRICING_HERO}${selectedRow}`, method:"put", data:data}).then(res=>{
 
        handleClose(); //close the dialog after successful submission
        PricingList()
    })
    .catch(error => {
        console.log(error);
    });
}

  // pricing content edit

  const handlePriceSaveRow = ({ row, values,exitEditingMode}) => {
    const requiredFields= ['topic','category'];
    const missingFields = requiredFields.filter((field) =>
      !values[field]);
      if(missingFields.length > 0){
        alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
  return;
      }
      

    console.log("aishuuuuuuuuuuu testtttttttt" + row.original.id);
    console.log(values);
    if (!values) {
      return;
    }

    const data={
      topic:values.topic,
      category:values.category,
      pricingOrder:values.pricingOrder,
      categoryOrder:values.categoryOrder,
      basic:values.basic,
      extended:values.extended, 
      premium:values.premium, 
    }

    console.log("this is edit data testing" + JSON.stringify(data));
    
     request({url:`${EDIT_PRICING_CONTENT}${row.original.id}`, method:"put", data:data}).then(res=>{
        
        const updatedsData = [...priceContentResponse]
        updatedsData[row.index] = res.data
        setPriceContentResponse(updatedsData)
        exitEditingMode();
     
      })
      .catch(error => {
        console.log(error)
      })
  }

  //pricing hero

  const handleAddSubmit=(data)=>{
   

    const postData={
      topic:data.topic,
      description:content,
      buttonUrl:data.buttonUrl,
      amount:data.amount
    }
   
    request({url:ADD_PRICING_HERO, method:"post", data:postData}).then(res=>{
      console.log(res);
      if(res.status===200){
        handleAddClose();
        PricingList();
        reset();
        // setFile(null);
                      }
    }).catch(error=>{
      console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
  }


  //pricing content
  const handlePricingAddSubmit=(data)=>{
   

    const postData={
      topic:data.topic,
      category:data.category,
      pricingOrder:data.pricingOrder,
      categoryOrder:data.categoryOrder,
      basic:data.basic,
      extended:data.extended,
      premium:data.premium
    }

    console.log( " this is postdata" + JSON.stringify(postData) );

    // return;
   
    request({url:ADD_PRICING_CONTENT, method:"post", data:postData}).then(res=>{
      console.log(res);
      if(res.status===200){
        handlePriceAddClose();
        PricingContentList();
        reset();
        // setFile(null);
                      }
    }).catch(error=>{
      console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
  }



  const PricingList=() =>{
    axios.get(BASE_URL+GET_PRICING_HERO)
    .then((res)=>{
        setResponse(res.data)
       
        console.log("step 1" +res.data.data);
        console.log("step 2" +JSON.stringify(res.data) );
        console.log("step 3" +res);
    })
    .catch((e) =>{
        console.log(e)
        

    }) 
}
useEffect(()=>{
  PricingList();
},[])


const PricingContentList=() =>{
  axios.get(BASE_URL+GET_PRICING_CONTENT)
  .then((res)=>{
      setPriceContentResponse(res.data)
     
      console.log("step 1" +res.data.data);
      console.log("step 2" +JSON.stringify(res.data) );
      console.log("step 3" +res);
  })
  .catch((e) =>{
      console.log(e)
      

  }) 
}
useEffect(()=>{
  PricingContentList();
},[])
const handleClickOpen = () => {
  setOpen(true);
    
};

const handleClickPriceOpen = () => {
  setPricingOpen(true);
    
};
const handleClose = () => {
  setSelectedRowId(null);
  setOpen(false);
  setEditorres('');

};

const handlePricingClose = () => {
  setPricingOpen(false);
  

};

const handleAddClose =()=>{
  setOpen(false);
  reset();
  // setFile(null);
}

const handlePriceAddClose =()=>{
  setPricingOpen(false);
  reset();
  // setFile(null);
}

const handleSnackbarClose = () => {
  setOpenSnackbar(false);
};

  const columns = useMemo(
    (rowData) => [
      {
        accessorKey:'id',
        header:'Id',
        enableHiding:false
      },
      {
        accessorKey: 'topic',
        header: 'Topic',
        accessorFn:(row)=>row?row.topic:''
      
      },
      {
        accessorFn:(row)=>striptags(row.description),
        header: 'Description',
        accessorKey:'description'
        // accessorFn: (row) => row.username,
      },
      
     
      {
        accessorKey: 'buttonUrl',
        header: 'Button URL',
        accessorFn:(row)=>row?row.buttonUrl:''
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        accessorFn: (row) => row ?row.amount: 0,
         
          },
      
        
      ],
    [striptags]
  );

// Pricing Content


const columnsPriceContent = useMemo(
  (rowData) => [
    {
      accessorKey:'id',
      header:'Id',
      enableHiding:false
    },
    {
      accessorKey: 'topic',
      header: 'Topic',
      accessorFn:(row)=>row?row.topic:''
    
    },
    {
      accessorKey:'category',
      accessorFn: (row) =>row?row.category:'',
      header: 'Category',
      // accessorFn: (row) => row.username,
    },
    {
      accessorKey:'pricingOrder',
      accessorFn: (row) =>row?row.pricingOrder:'',
      header: 'Topic Order',
      // accessorFn: (row) => row.username,
    },
    {
      accessorKey:'categoryOrder',
      accessorFn: (row) =>row?row.categoryOrder:'',
      header: 'categoryOrder',
      // accessorFn: (row) => row.username,
    },
    
   
    {
    
       accessorKey: 'basic',
      header: 'Basic',
      accessorFn: (row) => row?row.basic:'',
      // {
      //   if (row && row.basic) {
      //     if (row.basic === 'Y') {
      //       return 'Yes';
      //     } else if (row.basic === 'N') {
      //       return 'No';
      //     } else if (row.basic === 'O') {
      //       return 'Optional';
      //     }
      //   }
      //   return  row.basic  },
      editVariant: 'select',
      editSelectOptions: [
        {
          value: 'Y',
          text: 'Yes'
        },
        {
          value: 'N',
          text: 'No'
        },
        {
          value: 'O',
          text: 'Optional'
        },
        {
          value: 'Extra charges may apply',
          text: 'Extra charges may apply'
        }
      ],
      editDefaultValue: (rowData) => rowData.basic, // Set a default value during editing
      onEditChange: (newValue, rowData, rowIndex) => {
        rowData.basic = newValue;
        // rowData.basic = newValue === 'Yes' ? true : false;
      }
    },
    
    
    {
      accessorKey: 'extended',
      header: 'Extended',
      accessorFn: (row) => row?row.extended:'',
      // {
      //   if (row && row.extended) {
      //     if (row.extended === 'Y') {
      //       return 'Yes';
      //     } else if (row.extended === 'N') {
      //       return 'No';
      //     } else if (row.extended === 'O') {
      //       return 'Optional';
      //     }
      //   }
      //   // return 'No'; // Default value if row or row.basic is undefined
      // },
      editVariant: 'select',
      editSelectOptions: [
        {
          value: 'Y',
          text: 'Yes'
        },
        {
          value: 'N',
          text: 'No'
        },
        {
          value: 'O',
          text: 'Optional'
        },
        {
          value: 'Extra charges may apply',
          text: 'Extra charges may apply'
        }
      ],
      onEditChange: (newValue, rowData, rowIndex) => {
        rowData.extended = newValue; // Update the value directly since it's already in the desired format
      }
    }
    ,
    {
      accessorKey: 'premium',
      header: 'Premium',
      accessorFn: (row) => row?row.premium:'',
      // {
      //   if (row && row.premium) {
      //     if (row.premium === 'Y') {
      //       return 'Yes';
      //     } else if (row.premium === 'N') {
      //       return 'No';
      //     } else if (row.premium === 'O') {
      //       return 'Optional';
      //     }
      //   }
      //   // return 'No'; // Default value if row or row.basic is undefined
      // },
      editVariant: 'select',
      editSelectOptions: [
        {
          value: 'Y',
          text: 'Yes'
        },
        {
          value: 'N',
          text: 'No'
        },
        {
          value: 'O',
          text: 'Optional'
        },
        {
          value: 'Extra charges may apply',
          text: 'Extra charges may apply'
        }
      ],
      onEditChange: (newValue, rowData, rowIndex) => {
        rowData.premium = newValue; // Update the value directly since it's already in the desired format
      }
    },
      
    ],
  []
);


  return (
    
    <div className="mt-3">
       <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}>
        Add
      </Button>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
        <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
        <DialogContent>
        <Form onSubmit={handleSubmit(handleAddSubmit)}>
                  <Controller
  name="topic"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
 
    minLength: { value: 3, message: 'Topic should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic"
      margin='dense'
      error={Boolean(errors?.topic?.message)}
      helperText={errors?.topic?.message}
     
    />
  )}
/>

<Controller
  name="amount"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Amount is required' },
    pattern: {
      value: /^\d+$/,
      message: 'Amount should contain only numbers',
    },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
   
    minLength: { value: 1, message: 'Amount should be at least 1 digit long' },
    maxLength: { value: 6, message: 'Amount should not exceed 6 digits' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Amount"
      margin='dense'
      error={Boolean(errors?.amount?.message)}
      helperText={errors?.amount?.message}
     
    />
  )}
/>

                    
            <Form.Group>

            <JoditEditor
  value={content}
  config={config}
  onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
  onChange={newContent => {}}
  tabIndex={1} // tabIndex of textarea
/>
             {/* <Controller
  name="description"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Description is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Description is required',
    },
    minLength: { value: 3, message: 'Description should be at least 3 characters long' },
    maxLength: { value: 5000, message: 'Description should not exceed 5000 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Description"
      margin='dense'
      error={Boolean(errors?.description?.message)}
      helperText={errors?.description?.message}
     
    />
  )}
/> */}
            </Form.Group>
            <Form.Group>
            
   <Controller
  name="buttonUrl"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'ButtonUrl is required' },
    pattern: {
     value: /^(http|https):\/\/[a-z0-9]+([-.\w]+)*\.[a-z]{2,5}(\d{1,5})?\/?.*$/i
     ,
            message: 'ButtonUrl should be a valid website URL',
    },
    minLength: { value: 3, message: 'ButtonUrl should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Button Url"
      margin='dense'
      error={Boolean(errors?.buttonUrl?.message)}
      helperText={errors?.buttonUrl?.message}
     
    />
  )}
/>
            </Form.Group>
        <DialogActions>
        <Button onClick={handleAddClose}>Cancel</Button>
    <Button type="submit" color="primary" >
      Submit
    </Button>
        </DialogActions>
          </Form>
 
        </DialogContent>
    
      </Dialog>
         <MaterialReactTable 
    columns={columns} 
    data={response}
    editingMode="row"
    // enableEditing
    // onEditingRowSave={handleSaveRow}

    renderRowActionMenuItems={({ row }) => [
      <>
    
      <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
      <IconButton color="error" onClick={() => handleDelete(row)} >
        <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
       </IconButton>
       </>
   
   ]}

 
    initialState={{ columnVisibility: { id: false } }}
    enableRowActions
    enableColumnFilters={false}
 
     
    />



<Dialog open={selectedRowId !== null} onClose={handleClose} fullWidth
  maxWidth="md">
  <DialogTitle>Edit</DialogTitle>
  <DialogContent>
    <Form>  

    <TextField
              fullWidth
              label="Topic"
              margin="dense"
              value={editTopic}
              onChange={(e) => setEditTopic(e.target.value)}/>
          <Form.Group>
        <Form.Label>Description</Form.Label>
      <JoditEditor
  value={editorres}
  config={config}
  tabIndex={1} // tabIndex of textarea
  onBlur={newContent => setEditorres(newContent)} // preferred to use only this option to update the content for performance reasons
  onChange={newContent => {}}
/>
      
      </Form.Group>
      <br/>
      <TextField
              fullWidth
              label="Amount"
              margin="dense"
              value={editamount}
              onChange={(e) => setEditAmount(e.target.value)}/>
<br/>
<br/>
      <TextField
              fullWidth
              label="Button Url"
              margin="dense"
              value={editButton}
              onChange={(e) => setEditButton(e.target.value)}/>
        


    </Form>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Cancel</Button>
    <Button onClick={handleEdits}>Save</Button>
  </DialogActions>
</Dialog>





    <div style={{marginTop:50}}>
      <h2>Pricing Content</h2>
      <br/>
      <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickPriceOpen}>
        Add
      </Button>

      <Dialog open={pricingOpen} onClose={handlePricingClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
        <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
        <DialogContent>
        <Form onSubmit={handleSubmit(handlePricingAddSubmit)}>
                  <Controller
  name="topic"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
   
    minLength: { value: 3, message: 'Topic should be at least 3 characters long' },
   
  }}
 
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic"
      margin='dense'
      error={Boolean(errors?.topic?.message)}
      helperText={errors?.topic?.message}
     
    />
  )}
/>
                    
            <Form.Group>
             <Controller
  name="category"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Category is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Category is required',
    },
    minLength: { value: 3, message: 'Category should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Category"
      margin='dense'
      error={Boolean(errors?.category?.message)}
      helperText={errors?.category?.message}
     
    />
  )}
/>
            </Form.Group>

            <Controller
  name="pricingOrder"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic Order is required' },
    pattern: {
      value: /^\d+$/,
      message: 'Topic Order should contain only numbers',
    },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic Order is required',
    },

    minLength: { value: 1, message: 'Topic Order should be at least 1 character long' },
  
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic Order"
      margin='dense'
      error={Boolean(errors?.pricingOrder?.message)}
      helperText={errors?.pricingOrder?.message}
     
    />
  )}
/>

<Controller
  name="categoryOrder"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'categoryOrder is required' },
    pattern: {
      value: /^\d+$/,
      message: 'categoryOrder should contain only numbers',
    },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'categoryOrder is required',
    },


    // validate: {
    //   hasNoWhitespace: value => value.trim() !== '' || 'categoryOrder is required',
    //   notUsedBefore: value => {
    //     if(usedOrders.has(value)) {
    //       console.log("This is number test if part by aishwaryaa");
    //       return 'This number has already been used';
    //     }
      
    //   }
    // },
    minLength: { value: 1, message: 'categoryOrder should be at least 1 character long' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="categoryOrder"
      margin='dense'
      error={Boolean(errors?.categoryOrder?.message)}
      helperText={errors?.categoryOrder?.message}
     
    />
  )}
/>
            <Form.Group>
              <br/>

              <Controller
  name="basic"
  control={control}
  defaultValue={""}
  rules={{
    validate: (value) => value !== "" || 'This field is required'
  }}
  render={({ field, fieldState: { error } }) => (
    <FormControl fullWidth error={Boolean(error)}>
      <InputLabel id="demo-simple-select-label">Basic</InputLabel>
      <Select
        {...field}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Basic"
      >
        <MenuItem value='Y'>Yes</MenuItem>
        <MenuItem value='N'>No</MenuItem>
        <MenuItem value='O'>Optional</MenuItem>
        <MenuItem value='Extra charges may apply'>Extra charges may apply</MenuItem>
      </Select>
      {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
    </FormControl>
  )}
/>
     
                 </Form.Group>
                 <Form.Group>
              <br/>

              <Controller
  name="extended"
  control={control}
  defaultValue={""}
  rules={{
    validate: (value) => value !== "" || 'This field is required'
  }}
  render={({ field, fieldState: { error } }) => (
    <FormControl fullWidth error={Boolean(error)}>
      <InputLabel id="demo-simple-select-label">Extended</InputLabel>
      <Select
        {...field}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Extended"
      >
       <MenuItem value='Y'>Yes</MenuItem>
        <MenuItem value='N'>No</MenuItem>
        <MenuItem value='O'>Optional</MenuItem>
        <MenuItem value='Extra charges may apply'>Extra charges may apply</MenuItem>
      </Select>
      {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
    </FormControl>
  )}
/>
     
                 </Form.Group>

                 <Form.Group>
              <br/>

              <Controller
  name="premium"
  control={control}
  defaultValue={""}
  rules={{
    validate: (value) => value !== "" || 'This field is required'
  }}
  render={({ field, fieldState: { error } }) => (
    <FormControl fullWidth error={Boolean(error)}>
      <InputLabel id="demo-simple-select-label">Premium</InputLabel>
      <Select
        {...field}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Premium"
      >
         <MenuItem value='Y'>Yes</MenuItem>
        <MenuItem value='N'>No</MenuItem>
        <MenuItem value='O'>Optional</MenuItem>
        <MenuItem value='Extra charges may apply'>Extra charges may apply</MenuItem>
      </Select>
      {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
    </FormControl>
  )}
/>
     
                 </Form.Group>
        <DialogActions>
        <Button onClick={handlePriceAddClose}>Cancel</Button>
    <Button type="submit" color="primary" >
      Submit
    </Button>
        </DialogActions>
          </Form>
 
        </DialogContent>
    
      </Dialog>


    <MaterialReactTable 
    columns={columnsPriceContent} 
    data={priceContentResponse}
    editingMode="row" 
    enableEditing
    onEditingRowSave={handlePriceSaveRow}

    renderRowActionMenuItems={({ row }) => [ 
      <>
      {/* <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem> */}
       <IconButton color="error" onClick={() => handlePriceDelete(row)} >
         <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
        </IconButton>
        </>
        ]}
     initialState={{ columnVisibility: { id: false } }}
    enableRowActions
    enableColumnFilters={false}
 
     
    />
  
<Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>

 


    </div>

     
    </div>
  );
};


export default Pricing