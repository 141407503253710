import { useEffect, useMemo,  useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle,  MenuItem} from "@mui/material";
import { Form } from "react-bootstrap";
import { request } from "components/axiosconfig";
import { Delete, Edit } from "@mui/icons-material";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ALL_DATA_COLLECTION_AND_SECURITY_BY_ID } from "ApplicationURI";
import { DATA_COLLECTION_AND_SECURITY } from "ApplicationURI";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DATA_COLLECTION_AND_SECURITY_BY_ID } from "ApplicationURI";
import { EDIT_DATA_COLLECTION_AND_SECURITY_BY_ID } from "ApplicationURI";
import { DELETE_DATA_COLLECTION_AND_SECURITY_BY_ID } from "ApplicationURI";

function PrivacyPolicy() {
    const [response,setResponse] = useState([])
    const [open, setOpen] = useState(false);
    const [editorData, setEditorData] = useState('');
    const [updateRes,setUpdateRes] = useState([])
    const [enableButton,setEnableButton]=useState(true);

   
    const [editorres,setEditorres]=useState('');
 
    const [selectedRowId, setSelectedRowId] = useState(null);
    const striptags = require('striptags');
    const [errorMessage, setErrorMessage] = useState('');
const [openSnackbar, setOpenSnackbar] = useState(false);

    const selectedRow = updateRes;
    console.log('selectedRow:', selectedRow);



    const editorConfiguration = {
        toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'indent',
            'outdent',
            '|',
            'blockQuote',
            'insertTable',
            'imageUpload',
            'mediaEmbed',
            'undo',
            'redo'
        ],
      };

    const handleEditor= (event, editor) => {
        const data = editor.getData();
        setEditorres(data);
      };
  
 

      const PrivacyPolicyListById = (id) => {
        axios.get(`${BASE_URL}${DATA_COLLECTION_AND_SECURITY_BY_ID}${id}`)
          .then((res) => {
              console.log("iddd"+selectedRow)
            setUpdateRes(res.data.id);
            console.log("step 1" + res.data.data);
            console.log("step 2" + JSON.stringify(res.data));
            console.log("step 3" + res);
            setEditorres(res.data.data);
           
          })
          .catch((e) => {
            // handle error
          });
      };

      useEffect(() => {
        console.log("useEffect is running");
        console.log("updateRes:", updateRes);
      }, [updateRes]);
      

     
        
      const handleEdit = (selectedRow) => {
        console.log('selectedRow:', selectedRow); // check the value of selectedRow
        console.log('selectedRow.id:', selectedRow.id); // check the value of selectedRow.id
        setSelectedRowId(selectedRow.original.id);
        PrivacyPolicyListById(selectedRow.original.id);
      };
      
    // useEffect(() => {
    //     HeroListById()
    //   }, [])

      const handleEdits = (data) => {
        console.log("Button clicked  in editttt");
        console.log(selectedRow+"selected");
        // console.log(JSON.stringify(row)  +"testttttt")
   
 
        // e.preventDefault();
        // const formData = new FormData();
        // formData.append('data',editorres)                           
        // const headers = {
        //     'Content-Type': 'multipart/form-data'
        //   };

        // if (editorres === ""||null) {
        //   setError("editorData", {
        //     type: "manual",
        //     message: "Please provide valid data ",
        //   });
        //   return;
        // }
        if(!editorres){
          alert('Please provide description');
          return;
        }
        const postData={
            data:editorres
           }
             
          request({url:`${EDIT_DATA_COLLECTION_AND_SECURITY_BY_ID}${selectedRow}`, method:"put", data:postData}).then(res=>{
     
            handleClose(); //close the dialog after successful submission
            PrivacyPolicyList()
        })
        .catch(error => {
            console.log(error);
        });
    }
  
    
  
    const handleAddSubmit=(data)=>{
    //   e.preventDefault();
    //   const formData = new FormData();
    //   formData.append('data',editorData)
    
    if(!editorData){
      alert('Please provide description');
      return;
    }
   const postData={
    data:editorData
   }
  
      request({url:DATA_COLLECTION_AND_SECURITY, method:"post", data:postData}).then(res=>{
        console.log(res);
        if(res.status===200){
          handleAddClose();
          PrivacyPolicyList();
              setEditorData('');
              // setFile(null)
           
  
            }
      }).catch(error=>{
        console.log(error.response); // Log the error response object
        if (error.response) {
          // Request was made and server responded with a status code
          console.log("error.response.status" + error.response.status);
          console.log("fhgfhgf" + error.response.data.message);
  
          if (error.response.status !== 200 || error.response.status !== 201) {
            setErrorMessage(error.response.data.message);
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          
          console.log(error.request);
        } else {
        
          console.log('Error', error.message);
        }
      });
    }
  
  
  
    const PrivacyPolicyList=() =>{
      axios.get(`${BASE_URL}${ALL_DATA_COLLECTION_AND_SECURITY_BY_ID}`)
      .then((res)=>{
        console.log(res.data[0])
        if (Array.isArray(res.data) && res.data.length !== 0) {
          setResponse([res.data[0]]) // Set data from the first item in the array
        } else {
          setResponse(''); // Set empty string if the data is not as expected
        }
          // setResponse(res)
         console.log("response"+res)
          console.log("step 1" +[res.data.data]);
          console.log("step 2" +JSON.stringify(res.data) );
          console.log("step 3" +res);
          if (res.data.length === 0) {
            setEnableButton(false);
          } else {
            setEnableButton(true);
          }
      })
      .catch((e) =>{
          console.log(e)
          
  
      }) 
  }
  useEffect(()=>{
    PrivacyPolicyList();
  },[])

//   function handleDelete(data) {
//     window.alert('Are you sure you want to delete this Data Collection and Security?');
//     console.log(data.original.id);
//     console.log("this is delete inside");
//     request({url:`${DELETE_DATA_COLLECTION_AND_SECURITY_BY_ID}${data.original.id}`,method:"delete"}).then(res=>{
//       console.log(res);
//       PrivacyPolicyList();
//     }).catch(error=>{
// console.log(error);
//     });
//     }

    function handleDelete(data) {
      const isConfirmed = window.confirm('Are you sure you want to delete this Data Collection and Security?');
  
      if (isConfirmed) {
        console.log(data.original.id);
        // console.log("This is delete inside");
  
        request({ url: `${DELETE_DATA_COLLECTION_AND_SECURITY_BY_ID}${data.original.id}`, method: "delete" })
          .then(res => {
            // console.log(res);
            PrivacyPolicyList();
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        // console.log('Deletion canceled.');
      }
    }
  const handleClickOpen = () => {
    setOpen(true);
      
  };
  
  const handleClose = () => {
    setSelectedRowId(null);
    setOpen(false);
    setEditorres('');
    // setFileEdit(null);
  };
  
  const handleAddClose =()=>{
    setOpen(false);
    // setFile(null)
    setEditorData('')
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

    const columns = useMemo(
        (rowData) => [
          {
            accessorKey:'id',
            header:'Id',
            enableHiding:false
          },
          {
            accessorKey: 'data',
            header: 'Topic',
            accessorFn:(row)=> striptags(row.data)
          
          },
          
        
          ],
        [striptags]
      );
  return (
    <div className="mt-3">
    <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}
    disabled={enableButton}>
     Add
   </Button>
   <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
   <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
maxWidth="md">
     <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
     <DialogContent>
     <Form>
              
         <Form.Group>
            <Form.Label>
                <h4>Description:</h4>
                <CKEditor
  editor={ClassicEditor}
  config={editorConfiguration}
  data={editorData}
  onChange={handleEditorChange}
  name="editorData"
/>
            </Form.Label>
          
                    </Form.Group>

       </Form>

     </DialogContent>
     <DialogActions>
     <Button onClick={handleAddClose}>Cancel</Button>
 <Button type="submit" color="primary" onClick={handleAddSubmit}>
   Submit
 </Button>
     </DialogActions>
   </Dialog>
      <MaterialReactTable 
 columns={columns} 
 data={response}
//  editingMode="row"
//  enableEditing
//  onEditingRowSave={handleSaveRow}

 renderRowActionMenuItems={({ row }) => [
    <>
  
    <MenuItem onClick={() => handleEdit(row)}> <Edit/> &nbsp;&nbsp;&nbsp;
    Edit</MenuItem>
    <MenuItem color="error" onClick={() => handleDelete(row)} >
      <Delete style={{color:'red'}} />  &nbsp;&nbsp;&nbsp; Delete
     </MenuItem>
     </>
 
 ]}

 initialState={{ columnVisibility: { id: false } }}
 enableRowActions
 enableColumnFilters={false}

 />

<Dialog open={selectedRowId !== null} onClose={handleClose} fullWidth
  maxWidth="md">
  <DialogTitle>Edit Row {selectedRow}</DialogTitle>
  <DialogContent>
    <Form>  
      <Form.Group>
      <CKEditor
  editor={ClassicEditor}
  config={editorConfiguration}
  data={editorres}
  onChange={handleEditor}
  name="editorData"
/>
      </Form.Group>

     </Form>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Cancel</Button>
    <Button onClick={handleEdits}>Save</Button>
  </DialogActions>
</Dialog>

  
 </div>
  )
}

export default PrivacyPolicy