import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Hero from "views/admin/hero";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdLock,
  MdOutlineForum,
  MdOutline6KPlus,
  MdOutlineStar,
  MdOutlinePriorityHigh,
  MdAnnouncement,
  MdContactPage,
  MdOutlineLockClock,
  MdOutlineChat
} from "react-icons/md";
import About from "views/admin/about";
import MainFeature from "views/admin/mainFeature";
import SubFeature from "views/admin/subFeature";
import ViewsFeedback from "views/admin/viewsFeedback";
import MainViewsFeedback from "views/admin/mainViewsFeedback";
import Blog from "views/admin/blog";
import Faq from "views/admin/faq";
import SubFaq from "views/admin/subfaq";
import Contact from "views/admin/contact";
import PrivacyPolicy from "views/admin/privacyPolicy-us";
import PrivacyPolicyIn from "views/admin/privacyPolicy-in";
import TermsConditionUs from "views/admin/termsCondition-us";
import TermsConditionIn from "views/admin/termsCondition-in";
import Subscribe from "views/admin/subscribe";
import Timeline from "views/admin/timeline";
import Pricing from "views/admin/pricing";
import Instructions from "views/admin/instructions";
import SubInstructions from "views/subInstuctions";
import FeaturesNotification from "views/admin/featuresInformation";
import DataCollectionAndSecurity from "../src/views/admin/DataCollectionAndSecurity"

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Hero",
    layout: "/admin",
    path: "hero",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Hero />,
    secondary: true,
  },
  {
    name: "About",
    layout: "/admin",
    path: "about",
    icon: <MdOutlinePriorityHigh className="h-6 w-6" />,
    component: <About />,
    secondary: true,
  },
  {
    name: "TimeLine",
    layout: "/admin",
    path: "timeline",
    icon: <MdOutlineForum className="h-6 w-6" />,
    component: <Timeline/>,
    secondary: true,
  },
  {
    name: "Pricing",
    layout: "/admin",
    path: "pricing",
    icon: <MdOutlineForum className="h-6 w-6" />,
    component: <Pricing/>,
    secondary: true,
  },
  {
    name: "Feature Category",
    layout: "/admin",
    path: "mainFeature",
    icon: <MdOutlineStar className="h-6 w-6" />,
    component: <MainFeature />,
    secondary: true,
  },
  {
    name: "Features Details",
    layout: "/admin",
    path: "subFeature",
    icon: <MdOutlineStar className="h-6 w-6" />,
    component: <SubFeature />,
    secondary: true,
  },
  {
    name: "Instructions",
    layout: "/admin",
    path: "instructions",
    icon: <MdOutlineStar className="h-6 w-6" />,
    component: <Instructions />,
    secondary: true,
  },
  {
    name: "Sub Instructions",
    layout: "/admin",
    path: "subInstructions",
    icon: <MdOutlineStar className="h-6 w-6" />,
    component: <SubInstructions />,
    secondary: true,
  },
  {
    name: "ViewsFeedback Card",
    layout: "/admin",
    path: "viewsfeedback",
    icon: <MdOutline6KPlus className="h-6 w-6" />,
    component: <ViewsFeedback />,
    secondary: true,
  },
  {
    name: "Views&FeedBack",
    layout: "/admin",
    path: "mainviewsfeedback",
    icon: <MdOutline6KPlus className="h-6 w-6" />,
    component: <MainViewsFeedback />,
    secondary: true,
  },
  {
    name: "Blog",
    layout: "/admin",
    path: "blog",
    icon: <MdOutlineChat className="h-6 w-6" />,
    component: <Blog />,
    secondary: true,
  }, 
  {
    name: "Faq",
    layout: "/admin",
    path: "faq",
    icon: <MdAnnouncement className="h-6 w-6" />,
    component: <Faq/>,
    secondary: true,
  },
  {
    name: "Faq Card",
    layout: "/admin",
    path: "subfaq",
    icon: <MdAnnouncement className="h-6 w-6" />,
    component: <SubFaq/>,
    secondary: true,
  },
  {
    name: "Contact Details",
    layout: "/admin",
    path: "contact",
    icon: <MdContactPage className="h-6 w-6" />,
    component: <Contact/>,
    secondary: true,
  },
  {
    name: "Privacy Policy-Us",
    layout: "/admin",
    path: "privacypolicyus",
    icon: <MdOutlineLockClock className="h-6 w-6" />,
    component: <PrivacyPolicy/>,
    secondary: true,
  },
    {
    name: "Privacy Policy-In",
    layout: "/admin",
    path: "privacypolicyin",
    icon: <MdOutlineLockClock className="h-6 w-6" />,
    component: <PrivacyPolicyIn/>,
    secondary: true,
  },
  {
    name: "Data Collection",
    layout: "/admin",
    path: "datacollection&security",
    icon: <MdOutlineLockClock className="h-6 w-6" />,
    component: <DataCollectionAndSecurity/>,
    secondary: true,
  },
  {
    name: "Terms&Condition-In",
    layout: "/admin",
    path: "termsconditionIn",
    icon: <MdOutlineForum className="h-6 w-6" />,
    component: <TermsConditionIn/>,
    secondary: true,
  },
  {
    name: "Terms&Condition-Us",
    layout: "/admin",
    path: "termsconditionUs",
    icon: <MdOutlineForum className="h-6 w-6" />,
    component: <TermsConditionUs/>,
    secondary: true,
  },
  {
    name: "Subscribe",
    layout: "/admin",
    path: "subscribe",
    icon: <MdOutlineForum className="h-6 w-6" />,
    component: <Subscribe/>,
    secondary: true,
  },
  {
    name: "Features Notification",
    layout: "/admin",
    path: "featuresNotification",
    icon: <MdOutlineForum className="h-6 w-6" />,
    component: <FeaturesNotification/>,
    secondary: true,
  },
  //  {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
