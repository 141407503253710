import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,  TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { useForm, Controller } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GET_TIMELINE } from "ApplicationURI";
import { ADD_TIMELINE } from "ApplicationURI";
import { EDIT_TIMELINE } from "ApplicationURI";
import { DELETE_TIMELINE } from "ApplicationURI";

function Timeline() {
  const [response,setResponse] = useState([])
  const [open, setOpen] = useState(false);

  const { control, handleSubmit,reset, formState: { errors }} = useForm( );
  const [errorMessage, setErrorMessage] = useState('');
const [openSnackbar, setOpenSnackbar] = useState(false);





//pricing hero
  function handleDelete(data) {
    window.alert('Are you sure you want to delete this TimeLine?');
    console.log(data.original.id);
    console.log("this is delete inside");
    request({url:`${DELETE_TIMELINE}${data.original.id}`, method: "delete"})
    .then(res=>{
      console.log(res);
      TimelineList();
    }).catch(error=>{
console.log(error);
    });
    }

  
    //pricing hero

  const handleSaveRow = ({ row, values,exitEditingMode}) => {
    const requiredFields= ['topic','description','year'];
    const missingFields = requiredFields.filter((field) =>
      !values[field]);
      if(missingFields.length > 0){
        alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
  return;
      }

      const year = Number(values.year);

      if (isNaN(year) || year.toString().length !== 4) {
        alert('Year should be a 4-digit number');
        return;
      }
      if (year <= 2000) {
        alert('Year should be greater than 2000');
        return;
      }
      

    console.log("aishuuuuuuuuuuu testtttttttt" + row.original.id);
    console.log(values);
    if (!values) {
      return;
    }

    const data={
      topic:values.topic,
      description:values.description,
      // buttonUrl:values.buttonUrl,
      year:values.year
    }
     request({url:`${EDIT_TIMELINE}${row.original.id}`, method:"put", data:data}).then(res=>{
        
        const updatedData = [...response]
        updatedData[row.index] = res.data
        setResponse(updatedData)
        exitEditingMode();
     
      })
      .catch(error => {
        console.log(error)
      })
  }

  //pricing hero

  const handleAddSubmit=(data)=>{
   

    const postData={
      topic:data.topic,
      description:data.description,
      year:data.year
    }
   
    request({url:ADD_TIMELINE, method:"post", data:postData}).then(res=>{
      console.log(res);
      if(res.status===200){
        handleAddClose();
        TimelineList();
        reset();
        // setFile(null);
                      }
    }).catch(error=>{
      console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
  }


  



  const TimelineList=() =>{
    axios.get(BASE_URL+GET_TIMELINE)
    .then((res)=>{
        setResponse(res.data)
       
        console.log("step 1" +res.data.data);
        console.log("step 2" +JSON.stringify(res.data) );
        console.log("step 3" +res);
    })
    .catch((e) =>{
        console.log(e)
        

    }) 
}
useEffect(()=>{
  TimelineList();
},[])

const handleClickOpen = () => {
  setOpen(true);
    
};

const handleClose = () => {
  setOpen(false);
  

};


const handleAddClose =()=>{
  setOpen(false);
  reset();
  // setFile(null);
}



const handleSnackbarClose = () => {
  setOpenSnackbar(false);
};

  const columns = useMemo(
    (rowData) => [
      {
        accessorKey:'id',
        header:'Id',
        enableHiding:false
      },
      {
        accessorKey: 'topic',
        header: 'Topic',
        accessorFn:(row)=>row?row.topic:''
      
      },
      {
        accessorKey:'description',
        accessorFn: (row) =>row?row.description:'',
        header: 'Description',
       
      },
    
      {
        accessorKey: 'year',
        header: 'Year',
        accessorFn:(row)=>row?row.year:''
      },
        
      ],
    []
  );




  return (
    
    <div className="mt-3">
       <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}>
        Add
      </Button>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
        <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
        <DialogContent>
        <Form onSubmit={handleSubmit(handleAddSubmit)}>
                  <Controller
  name="topic"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
   
    minLength: { value: 3, message: 'Topic should be at least 3 characters long' },
    
  }}
 
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic"
      margin='dense'
      error={Boolean(errors?.topic?.message)}
      helperText={errors?.topic?.message}
     
    />
  )}
/>

<Controller
  name="year"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Year is required' },
    pattern: {
      value: /^\d+$/,
      message: 'Year should contain only numbers',
    },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Year is required',
      above2000: value => parseInt(value, 10) > 2000 || 'Year should be above 2000',
          },
   
    minLength: { value: 4, message: 'Year should be at least 4 digits long' },
    maxLength: { value: 4, message: 'Year should not exceed 4 digits' },
   
  }}
 
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Year"
      margin='dense'
      error={Boolean(errors?.year?.message)}
      helperText={errors?.year?.message}
     
    />
  )}
/>
                    
            <Form.Group>
             <Controller
  name="description"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Description is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Description is required',
    },
    minLength: { value: 3, message: 'Description should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Description"
      margin='dense'
      error={Boolean(errors?.description?.message)}
      helperText={errors?.description?.message}
     
    />
  )}
/>
            </Form.Group>
        
        <DialogActions>
        <Button onClick={handleAddClose}>Cancel</Button>
    <Button type="submit" color="primary" >
      Submit
    </Button>
        </DialogActions>
          </Form>
 
        </DialogContent>
    
      </Dialog>
         <MaterialReactTable 
    columns={columns} 
    data={response}
    editingMode="row"
    enableEditing
    onEditingRowSave={handleSaveRow}

    renderRowActionMenuItems={({ row }) => [
     
  
       <IconButton color="error" onClick={() => handleDelete(row)} >
         <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
        </IconButton>
    
    ]}

 
    initialState={{ columnVisibility: { id: false } }}
    enableRowActions
    enableColumnFilters={false}
 
     
    />
  <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>

 


 

     
    </div>
  );
};

export default Timeline