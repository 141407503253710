import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import { GET_HERO_DETAILS } from "ApplicationURI";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,  TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { useForm, Controller } from 'react-hook-form';
import { DELETE_HERO } from "ApplicationURI";
import { EDIT_HERO } from "ApplicationURI";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { IMAGE } from "ApplicationURI";


const Hero = () => {
  const [response,setResponse] = useState([])
  const [open, setOpen] = useState(false);
 
  const [file, setFile] = useState(null); 
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const { control, handleSubmit,reset, formState: { errors }} = useForm( );
  const [errorMessage, setErrorMessage] = useState('');
const [openSnackbar, setOpenSnackbar] = useState(false);
const [isButtonDisabled, setButtonDisabled] = useState(true);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name); 
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    // if (!acceptedFiles[0]) {
    //   alert('Please select a file.');
    //   return;
    // }
    if (rejectedFiles.length > 0 || acceptedFiles.length === 0 || !acceptedFiles[0].type.startsWith('image/')) {
      alert('Please upload a valid image file.');
      return;
    }

 


    // Do something with the uploaded image file
    console.log(acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  };
  function handleDelete(data) {
    window.alert('Are you sure you want to delete this Hero Section?');
    console.log(data.original.id);
    console.log("this is delete inside");
    request({url:`${DELETE_HERO}${data.original.id}`, method: "delete"})
    .then(res=>{
      console.log(res);
      HeroList();
    }).catch(error=>{
console.log(error);
    });
    }

  const handleSaveRow = ({ row, values,exitEditingMode}) => {
    const requiredFields= ['topic','description','buttonUrl','imgAlternative'];
    const missingFields = requiredFields.filter((field) =>
      !values[field]);
      if(missingFields.length > 0){
        alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
  return;
      }

      if (values.imgAlternative.length < 2) {
        alert("Please provide a minimum of 2 characters.");
        return;
      }
      if (values.imgAlternative.length > 250) {
        alert('Alternative Image Name can\'t exceed 250 characters');
        return;
      }

    console.log("aishuuuuuuuuuuu testtttttttt" + row.original.id);
    console.log(values);
    if (!values) {
      return;
    }
    const formData = new FormData()

    formData.append('topic', values.topic)
    formData.append('description', values.description)
    formData.append('buttonUrl', values.buttonUrl)
    formData.append('image',selectedFile)
    formData.append('imgAlternative',values.imgAlternative);
   
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
       
    request({url:`${EDIT_HERO}${row.original.id}`, method:"put", data:formData, headers }).then(res=>{
        
        const updatedData = [...response]
        updatedData[row.index] = res.data
        setResponse(updatedData)
        exitEditingMode();
     
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleAddSubmit=(data)=>{
   
    // data.preventDefault();
    const formData = new FormData();
    formData.append('topic',data.topic)
 
    formData.append('description',data.description)
    formData.append('buttonUrl',data.buttonUrl)
    formData.append('image',file)
    formData.append('imgAlternative',data.imgAlternative);
    

    if (!file) {
      alert('Please select a Image.');
      return;
    }

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    request({url:GET_HERO_DETAILS, method:"post", data:formData, headers }).then(res=>{
      console.log(res);
      if(res.status===200){
        handleAddClose();
        HeroList();
        reset();
        setFile(null);
                      }
    }).catch(error=>{
      console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
  }



  const HeroList=() =>{
    axios.get(BASE_URL+GET_HERO_DETAILS)
    .then((res)=>{
        setResponse(res.data)
       
        console.log("step 1" +res.data.data);
        console.log("step 2" +JSON.stringify(res.data) );
        console.log("step 3" +res);
        if (res.data.length === 0) {
          setButtonDisabled(false);
        } else {
          setButtonDisabled(true);
        }
    })
    .catch((e) =>{
        console.log(e)
        

    }) 
}
useEffect(()=>{
    HeroList();
},[])
const handleClickOpen = () => {
  setOpen(true);
    
};

const handleClose = () => {
  setOpen(false);
  

};

const handleAddClose =()=>{
  setOpen(false);
  reset();
  setFile(null);
}

const handleSnackbarClose = () => {
  setOpenSnackbar(false);
};

  const columns = useMemo(
    (rowData) => [
      {
        accessorKey:'id',
        header:'Id',
        enableHiding:false
      },
      {
        accessorKey: 'topic',
        header: 'Topic',
        accessorFn:(row)=>row?row.topic:''
      
      },
      {
        accessorKey:'description',
        accessorFn: (row) =>row?row.description:'',
        header: 'Description',
        // accessorFn: (row) => row.username,
      },
      
     
      {
        accessorKey: 'buttonUrl',
        header: 'Button URL',
        accessorFn:(row)=>row?row.buttonUrl:''
      },
      {
        accessorKey: 'imgAlternative',
        header: 'Alternative Image Name',
        accessorFn:(row)=>row?row.imgAlternative:''
      },
      {
        accessorKey: 'imageUrl',
        header: 'Image',
        accessorFn:(row)=>row?row.imageUrl:'',
        muiTableBodyCellEditTextFieldProps: {
          type: 'file',
          value:null,
          onChange: handleFileChange
        
        },
        render: (row) => {
          return (
            <div>
             
              {selectedFileName}
            </div>
          );
        }
      },
    
      ],
    [selectedFileName]
  );
  return (
    
    <div className="mt-3">
       <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen} disabled={isButtonDisabled}>
        Add
      </Button>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
        <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
        <DialogContent>
        <Form onSubmit={handleSubmit(handleAddSubmit)}>
                  <Controller
  name="topic"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
    // pattern: {
    //   value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
    //   message: 'Topic should contain only alphabetic characters',
    // },
    minLength: { value: 3, message: 'Topic should be at least 3 characters long' },
    // maxLength: { value: 250, message: 'Topic should not exceed 250 characters' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic"
      margin='dense'
      error={Boolean(errors?.topic?.message)}
      helperText={errors?.topic?.message}
     
    />
  )}
/>
                    
            <Form.Group>
             <Controller
  name="description"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Description is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Description is required',
    },
    minLength: { value: 3, message: 'Description should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Description"
      margin='dense'
      error={Boolean(errors?.description?.message)}
      helperText={errors?.description?.message}
     
    />
  )}
/>
            </Form.Group>
            <Form.Group>
            
   <Controller
  name="buttonUrl"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'ButtonUrl is required' },
    pattern: {
     value: /^(http|https):\/\/[a-z0-9]+([-_.][a-z0-9]+)*\.[a-z]{2,5}([0-9]{1,5})?\/?.*$/i,

            message: 'ButtonUrl should be a valid website URL',
    },
    minLength: { value: 3, message: 'ButtonUrl should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Button Url"
      margin='dense'
      error={Boolean(errors?.buttonUrl?.message)}
      helperText={errors?.buttonUrl?.message}
     
    />
  )}
/>

<Controller
  name="imgAlternative"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Alternative Image Name  is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Alternative Image Name  is required',
    },
    // pattern: {
    //   value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
    //   message: 'Topic should contain only alphabetic characters',
    // },
    minLength: { value: 2, message: 'Please provide a minimum of 2 characters' },
    maxLength: { value: 250, message: 'Alternative Image Name can\'t exceed 250 characters' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="AlterNative Image"
      margin='dense'
      error={Boolean(errors?.imgAlternative?.message)}
      helperText={errors?.imgAlternative?.message}
     
    />
  )}
/>
            </Form.Group>
            <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <>
            <div {...getRootProps()}>
              <p>Drag and drop your file here, or click to select a file</p>
            </div>
            <input {...getInputProps()} />
            {file && (
              <p>File uploaded: {file.name}</p>
            )}
          </>
        )}
      </Dropzone>
  <DialogActions>
        <Button onClick={handleAddClose}>Cancel</Button>
    <Button type="submit" color="primary" >
      Submit
    </Button>
        </DialogActions>
          </Form>
 
        </DialogContent>
    
      </Dialog>
         <MaterialReactTable 
    columns={columns} 
    data={response}
    editingMode="row"
    enableEditing
    onEditingRowSave={handleSaveRow}

    renderRowActionMenuItems={({ row }) => [
     
  
       <IconButton color="error" onClick={() => handleDelete(row)} >
         <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
        </IconButton>
    
    ]}

    renderDetailPanel={({ row }) => (
      <Box
        sx={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
           {console.log(row.original.imageurl)}
        
           <img src={`${BASE_URL}${IMAGE}${row.original.imageUrl}`} alt="" />

        
      </Box>
    )}
  
    initialState={{ columnVisibility: { id: false } }}
    enableRowActions
    enableColumnFilters={false}
 
  
    
    />

     
    </div>
  );
};

export default Hero;
