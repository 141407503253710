import { useEffect, useMemo,  useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { GET_BLOG_DETAILS } from "ApplicationURI";
import { ADD_BLOG } from "ApplicationURI";
import { GET_ALL_BLOG_BY_ID } from "ApplicationURI";
import { EDIT_BLOG } from "ApplicationURI";
import { DELETE_BLOG } from "ApplicationURI";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { IMAGE } from "ApplicationURI";
import JoditEditor from "jodit-react";



function Blog() {
    const [response,setResponse] = useState([])
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null); 
    const [meta,setMeta] = useState('');
    const[alt,setAlt] = useState('');
    const[editAlts,setEditAlt] = useState('');
    const[editMeta,setEditMeta] = useState('');

    const [updateRes,setUpdateRes] = useState([])
    const [content, setContent] = useState('');
    
    
    const [topic,setTopic] = useState('');
    const [editTopic,setEditTopic] = useState('');
  
    const [editorres,setEditorres]=useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    
    const [fileEdit,setFileEdit] = useState(null);
    const striptags = require('striptags');
    
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const selectedRow = updateRes;


    const config = {
      readonly: false,
      toolbarButtonSize: 'middle',
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
       toolbarAdaptive: false,
      toolbarSticky: false,
      hidePoweredByJodit: true,
        removeButtons: ['hr', 'source','video','print','preview','about','copyformat',],
      limitWords:false,
           toolbarStickyOffset: 0,
      toolbar: [
         
          'underline',
          'strikethrough',
          'eraser',
          '|',
          'ul',
          'ol',
          '|',
          'outdent',
          'indent',
          '|',
          'brush',
          'paragraph',
          '|',
          'image',
          'table',
          'link',
          '|',
          'align',
          'undo',
          'redo',
          '|',
          'cut',
          'hr',
          'symbol',
          'selectall',
          'copyformat',
          '|',
          'print',
          'preview',
          'about',
      ]
    };


 
  
      const onDrop = (acceptedFiles, rejectedFiles) => {
        // if (!acceptedFiles[0]) {
        //   alert('Please select a file.');
        //   return;
        // }
        if (rejectedFiles.length > 0 || acceptedFiles.length === 0 || !acceptedFiles[0].type.startsWith('image/')) {
          alert('Please upload a valid image file.');
          return;
        }
    
     
    
    
        // Do something with the uploaded image file
        console.log(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
      };

  
    const onDropEdit =  (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0 || acceptedFiles.length === 0 || !acceptedFiles[0].type.startsWith('image/')) {
        alert('Please upload a valid image file.');
        return;
      }
        // Do something with the uploaded file
        
        console.log(acceptedFiles[0]+"fileeeeeeeeeeeeeeeeeeeeeeeeee");
        setFileEdit(acceptedFiles[0]);
      }
 

      const HeroListById = (id) => {
        axios.get(`${BASE_URL}${GET_ALL_BLOG_BY_ID}${id}`)
          .then((res) => {
              console.log("iddd"+selectedRow)
            setUpdateRes(res.data.id);
            console.log("step 1" + res.data.data);
            console.log("step 2" + JSON.stringify(res.data));
            console.log("step 3" + res);
            setEditAlt(res.data.imgAlternative);
            setEditTopic(res.data.topic);
            setEditMeta(res.data.meta);
            setEditorres(res.data.data);
            // setImages(res.data.image);
          })
          .catch((e) => {
            // handle error
          });
      };

        
      const handleEdit = (selectedRow) => {
        console.log('selectedRow:', selectedRow); // check the value of selectedRow
        console.log('selectedRow.id:', selectedRow.id); // check the value of selectedRow.id
        setSelectedRowId(selectedRow.original.id);
        HeroListById(selectedRow.original.id);
      };
      
    // useEffect(() => {
    //     HeroListById()
    //   }, [])

    const handleAltChange = (event) => {
      setAlt(event.target.value);
    };
    const handleTopicChange = (event) => {
      setTopic(event.target.value);
    };

    const handleMetaChange = (event) => {
      setMeta(event.target.value);
    };
    
      const handleEdits = (e) => {
        
   
        console.log("Button clicked  in editttt");
        console.log(selectedRow+"selected");
        const emptyFields = [];
        // console.log(JSON.stringify(row)  +"testttttt")
        if (!editorres) {
          emptyFields.push('Description');
        }
      
        if (!editAlts) {
          emptyFields.push('Alternative Image Name');
        }
        if (!editTopic) {
          emptyFields.push('Topic');
        }
       
      
        // Check if any fields are empty
        if (emptyFields.length > 0) {
          const error = `Fields are required: ${emptyFields.join(', ')}`;
          alert(error);
          // You can display the error message or handle it as needed
          return;
        }
        
        if (editAlts.length < 2) {
          alert('Alternative Image Name should contain at least 2 characters');
          return;
        }
        if (editTopic.length < 2) {
          alert('Topic should contain at least 2 characters');
          return;
        }
        if (editTopic.length > 250) {
          alert("Topic can't exceed 250 character");
          return;
        }
    
        if (editAlts.length > 250) {
          alert("Alternative Image Name can't exceed 250 characters");
          return;
        }
        if (editMeta.length < 10) {
          alert('Please provide a minimum of 10 characters in Meta name.');
          return;
        }
    
        if (editMeta.length > 250) {
          alert("Meta tag name can't exceed 250 characters");
          return;
        }
  
   
 
        e.preventDefault();
        const formData = new FormData();

        formData.append('data',editorres)
        formData.append('topic',editTopic)
         formData.append('image', fileEdit)
         formData.append('imgAlternative',editAlts)
         formData.append('meta',editMeta)

         console.log("testing"+editorres)

       
        //  return;                          
        const headers = {
            'Content-Type': 'multipart/form-data'
          };
             
          request({url:`${EDIT_BLOG}${selectedRow}`, method:"put", data:formData, headers }).then(res=>{
     
            handleClose(); //close the dialog after successful submission
            BlogList()
        })
        .catch(error => {
            console.log(error);
        });
    }
  
    
  
    const handleAddSubmit=(e)=>{

      if (!file) {
        alert('Please select a Image.');
        return;
      }
      const emptyFields = [];

      // Validation checks
    
      if (!content) {
        emptyFields.push('Description');
      }
    
      if (!alt) {
        emptyFields.push('Alternative Image Name');
      }
     
    
      // Check if any fields are empty
      if (emptyFields.length > 0) {
        const error = `Fields are required: ${emptyFields.join(', ')}`;
        alert(error);
        // You can display the error message or handle it as needed
        return;
      }
      
      if (alt.length < 2) {
        alert('Alternative Image Name should contain at least 2 characters');
        return;
      }
  
      if (alt.length > 250) {
        alert("Alternative Image Name can't exceed 250 characters");
        return;
      }
      if (topic.length < 2) {
        alert('Topic should contain at least 2 characters');
        return;
      }
      if (topic.length > 250) {
        alert('Topic can\'t exceed 250 characters');
        return;
      }
  
      if (meta.length < 10) {
        alert('Please provide a minimum of 10 characters.');
        return;
      }
  
      if (meta.length > 250) {
        alert("Meta tag name can’t exceed 250 characters");
        return;
      }

      e.preventDefault();
      const formData = new FormData();
      // formData.append('data',editorData)
      formData.append('data', content);
      formData.append('topic', topic);
      formData.append('image',file)
      formData.append('imgAlternative',alt)
      formData.append('meta',meta)
      // 'testing' is the field name, content holds the Jodit Editor data
      console.log( content)
      console.log(alt);

    // return;    

    
     
      const headers = {
        'Content-Type': 'multipart/form-data'
      };
  
      request({url:ADD_BLOG, method:"post", data:formData, headers }).then(res=>{
        console.log(res);
        if(res.status===200){
          handleAddClose();
          BlogList();
          // setEditorData('');
          setFile(null);
            setAlt('');
            setMeta('');
            setTopic('');
              
           
  
            }
      }).catch(error=>{
        console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
    }
  
  
  
    const BlogList=() =>{
      axios.get(BASE_URL+GET_BLOG_DETAILS)
      .then((res)=>{
          setResponse(res.data)
         
          console.log("step 1" +res.data.data);
          console.log("step 2" +JSON.stringify(res.data) );
          console.log("step 3" +res);
      })
      .catch((e) =>{
          console.log(e)
          
  
      }) 
  }
  useEffect(()=>{
    BlogList();
  },[])

  function handleDelete(data) {
    window.alert('Are you sure you want to delete this Blog?');
    console.log(data.original.id);
    console.log("this is delete inside");
    request({url:`${DELETE_BLOG}${data.original.id}`,method:"delete"}).then(res=>{
      console.log(res);
      BlogList();
    }).catch(error=>{
console.log(error);
    });
    }
  const handleClickOpen = () => {
    setOpen(true);
      
  };
  
  const handleClose = () => {
    setSelectedRowId(null);
    setOpen(false);
    setEditorres('');
    setEditTopic('');
    setFileEdit(null);
  };
  
  const handleAddClose =()=>{
    setOpen(false);
    // setEditorData('');
    setEditTopic('');
    setFile(null)
  }
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };


    const columns = useMemo(
        (rowData) => [
          {
            accessorKey:'id',
            header:'Id',
            enableHiding:false
          },
          {
            accessorKey: 'topic',
            header: 'Topic',
            accessorFn:(row)=>row?row.topic:''
          
          },
          {
            accessorKey: 'data',
            header: 'Description',
            accessorFn:(row)=>striptags(row.data)
          
          },
            {
            accessorKey: 'meta',
            header: 'Meta tag Name',
            accessorFn:(row)=>row?row.meta:''
          },
          {
            accessorKey: 'imgAlternative',
            header: 'Alternative Image Name',
            accessorFn:(row)=>row?row.imgAlternative:''
          },
                 {
            accessorKey: 'imageUrl',
            header: 'Image',
            accessorFn:(row)=>row?row.imageUrl:'',
      
          },
        
          ],
        [striptags]
      );
  return (
    <div className="mt-3">
    <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}>
     Add
   </Button>
   <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
   <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
maxWidth="md">
     <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
     <DialogContent>
     <Form>
     <Form.Group >

    
    <TextField 
      id="alt-text" 
      label="Topic" 
      value={topic} 
      fullWidth
      onChange={handleTopicChange} 
      name="topic"
      variant="outlined"
      style={{marginTop:10}}
    />
  
</Form.Group>

         <Form.Group>
            <Form.Label>
                <h4>Description:</h4>
            {/* <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={editorData}
                onChange={handleEditorChange}
                name="editorData"
            /> */}
            </Form.Label>
          
                    </Form.Group>

                    <JoditEditor
  value={content}
  config={config}
  onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
  onChange={newContent => {}}
  tabIndex={1} // tabIndex of textarea
/>
      <br/>
                    <Form.Group>
  <Form.Label>
    
    <TextField 
      id="alt-text" 
      label="Alternative Image name" 
      value={alt} 
      fullWidth
      onChange={handleAltChange} 
      name="alt"
      variant="outlined"
    />
  </Form.Label>
</Form.Group>
<br/>
<Form.Group>
  <Form.Label>
    
    <TextField 
      id="alt-text" 
      label="Meta tag name" 
      value={meta} 
      fullWidth
      onChange={handleMetaChange} 
      name="alt"
      variant="outlined"
    />
  </Form.Label>
</Form.Group>


                    <br></br>
                    <h4>Upload Image:</h4>
                 <Dropzone onDrop={onDrop}>
 {({ getRootProps, getInputProps }) => (
   <>
     <div {...getRootProps()}>
       <p>Drag and drop your file here, or click to select a file</p>
     </div>
     <input {...getInputProps()} />
     {file && (
       <p>File uploaded: {file.name}</p>
     )}
   </>
 )}
</Dropzone>
       </Form>

     </DialogContent>
     <DialogActions>
     <Button onClick={handleAddClose}>Cancel</Button>
 <Button type="submit" color="primary" onClick={handleAddSubmit}>
   Submit
 </Button>
     </DialogActions>
   </Dialog>
      <MaterialReactTable 
 columns={columns} 
 data={response}
//  editingMode="row"
//  enableEditing
//  onEditingRowSave={handleSaveRow}

 renderRowActionMenuItems={({ row }) => [
    <>
  
    <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
    <IconButton color="error" onClick={() => handleDelete(row)} >
      <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
     </IconButton>
     </>
 
 ]}
 renderDetailPanel={({ row }) => (
  <Box
    sx={{
      display: 'grid',
      margin: 'auto',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
    }}
  >
       {console.log(row.original.imageUrl)}
    
       <img src={`${BASE_URL}${IMAGE}${row.original.imageUrl}`} alt="" />

    
  </Box>
)}
 initialState={{ columnVisibility: { id: false } }}
 enableRowActions
 enableColumnFilters={false}

 />

<Dialog open={selectedRowId !== null} onClose={handleClose} fullWidth
  maxWidth="md">
  <DialogTitle>Edit Row </DialogTitle>
  <DialogContent>
    <Form>  
    <TextField
              fullWidth
              label="Topic"
              margin="dense"
              value={editTopic}
              onChange={(e) => setEditTopic(e.target.value)}/>
      <Form.Group>
        <Form.Label>Description</Form.Label>
      <JoditEditor
  value={editorres}
  config={config}
  tabIndex={1} // tabIndex of textarea
  onBlur={newContent => setEditorres(newContent)} // preferred to use only this option to update the content for performance reasons
  onChange={newContent => {}}
/>
        {/* <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={editorres}
          onChange={handleEditor}
          name="herosectiondescription"
        /> */}
      </Form.Group>
      <TextField
              fullWidth
              label="Alternative Image Name"
              margin="dense"
              value={editAlts}
              onChange={(e) => setEditAlt(e.target.value)}/>
      <TextField
              fullWidth
              label="Meta tag Name"
              margin="dense"
              value={editMeta}
              onChange={(e) => setEditMeta(e.target.value)}/>
    

      <Dropzone onDrop={onDropEdit}>
    {({ getRootProps, getInputProps }) => (
      <>
        <div {...getRootProps()}>
          <p>Drag and drop your file here, or click to select a file</p>
        </div>
        <input {...getInputProps()} />
        {fileEdit && (
          <p>File uploaded: {fileEdit.name}</p>
        )}
      </>
    )}
  </Dropzone>
    </Form>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Cancel</Button>
    <Button onClick={handleEdits}>Save</Button>
  </DialogActions>
</Dialog>

  
 </div>
  )
}

export default Blog