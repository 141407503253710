
import { BASE_URL } from "ApplicationURI";
import axios from "axios";

const client =axios.create({baseURL:BASE_URL})


export  const request =({...options})=>{
    const token=localStorage.getItem("token")
    client.defaults.headers.common.Authorization=`Bearer ${token}`
    const onSuccess=(response) => response
    const onError =(error) => {
        return error
    }
    return client(options).then(onSuccess).catch(onError)
}