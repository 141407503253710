import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, Select, TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { request } from "components/axiosconfig";
import { Delete, Edit } from "@mui/icons-material";
import { useForm, Controller } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
 
import { ADD_INSTRUCTIONS } from "ApplicationURI";
import { GET_INSTRUCTIONS } from "ApplicationURI";
import { GET_INSTRUCTIONS_BY_ID } from "ApplicationURI";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UPDATE_INSTRUCTIONS } from "ApplicationURI";
import { DELETE_INSTRUCTIONS } from "ApplicationURI";


function Instructions() {

    const [response,setResponse] = useState([])
    const [open, setOpen] = useState(false);
    const [updateRes,setUpdateRes] = useState([])
    const [topic,setTopic]=useState('')
    const [alt,setAlt]=useState('')
    const [file, setFile] = useState(null); 
    const [editorres,setEditorres]=useState('');
    const { control, handleSubmit,reset, formState: { errors }} = useForm( );
  const [openSnackbar, setOpenSnackbar] = useState(false);
   
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [fileEdit,setFileEdit] = useState(null);
    // const [editorres,setEditorres]=useState('');
    const [editorData, setEditorData] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');

    const selectedRow = updateRes;
   

    const onDrop = (acceptedFiles, rejectedFiles) => {
           console.log(acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    };

  

    const handleAddSubmit=(data)=>{
      // e.preventDefault();
      const formData = new FormData();
      formData.append('topic',data.topic)
      formData.append('description',editorData)
      formData.append('image',file)
      formData.append('orders', selectedOrder)
      formData.append('imgAlternative',data.imgAlternative);


      if (!file) {
        alert('Please select a Image.');
        return;
      }
      if(!editorData){
        alert('Please provide description');
        return;
      }

      // if (alt.length < 2) {
      //   alert('Alternative Image Name should contain at least 2 characters');
      //   return;
      // }
  
      const headers = {
        'Content-Type': 'multipart/form-data'
      };
  

      request({url:ADD_INSTRUCTIONS, method:"post", data:formData, headers }).then(res=>{
        console.log(res);
        if(res.status===200){
        handleAddClose();
        AboutList();
        reset();
        setFile(null);
  
            }
      }).catch(error=>{
        console.log(error.response); // Log the error response object
        if (error.response) {
          // Request was made and server responded with a status code
          console.log("error.response.status" + error.response.status);
          console.log("fhgfhgf" + error.response.data.message);
  
          if (error.response.status !== 200 || error.response.status !== 201) {
            // setErrorMessage(error.response.data.message);
            // setOpenSnackbar(true);
          }
        } else if (error.request) {
          
          console.log(error.request);
        } else {
        
          console.log('Error', error.message);
        }
      });
    }

    const InstructionByIDList=(id)=>{
        axios.get(`${BASE_URL}${GET_INSTRUCTIONS_BY_ID}${id}`)
        .then((res) => {
            console.log("iddd"+selectedRow)
          setUpdateRes(res.data.id);
          console.log("step 1" + res.data.data);
          console.log("step 2" + JSON.stringify(res.data));
          console.log("step 3" + res);
        //   setEditorres(res.data.data);
        setAlt(res.data.imgAlternative);
        setTopic(res.data.topic)
        setEditorres(res.data.description);
        // setDescription(res.data.description)
        console.log("testttttttttttttt"+res.data.topic)
         
        })
        .catch((e) => {
          // handle error
        });
    };

    const onDropEdit =  (acceptedFiles, rejectedFiles) => {
        // if (rejectedFiles.length > 0 || acceptedFiles.length === 0 || !acceptedFiles[0].type.startsWith('image/')) {
        //   alert('Please upload a valid image file.');
        //   return;
        // }
          // Do something with the uploaded file
          
          console.log(acceptedFiles[0]+"fileeeeeeeeeeeeeeeeeeeeeeeeee");
          setFileEdit(acceptedFiles[0]);
        }

      
    const handleEdit = (selectedRow) => {
      console.log('selectedRow:', selectedRow); // check the value of selectedRow
      console.log('selectedRow.id:', selectedRow.id); // check the value of selectedRow.id
      setSelectedRowId(selectedRow.original.id);
      InstructionByIDList(selectedRow.original.id);
    };

    const handleEdits = (e) => {

        const emptyFields = [];

        // Validation checks
        if (!topic) {
          emptyFields.push('Topic');
        }
        if (!editorres) {
          emptyFields.push('Description');
        }

        if (!alt) {
          emptyFields.push('Alternative Image Name');
        }
             
      
        // Check if any fields are empty
        if (emptyFields.length > 0) {
          const error = `Fields are required: ${emptyFields.join(', ')}`;
          alert(error);
          // You can display the error message or handle it as needed
          return;
        }

        if (alt.length < 2) {
          alert('Alternative Image Name should contain at least 2 characters');
          return;
        }

        if (alt.length > 250) {
          alert("Alternative Image Name can't exceed 250 characters");
          return;
        }
        
        console.log("Button clicked  in editttt");
        console.log(selectedRow+"selected");
        // console.log(JSON.stringify(row)  +"testttttt")
   
 
        e.preventDefault();
        const formData = new FormData();

        formData.append('topic',topic);
        formData.append('description',editorres);
         formData.append('image', fileEdit);   
         formData.append('orders', selectedOrder);
         formData.append('imgAlternative',alt);
                         
        const headers = {
            'Content-Type': 'multipart/form-data'
          };
             
          request({url:`${UPDATE_INSTRUCTIONS}${selectedRow}`, method:"put", data:formData, headers }).then(res=>{
     
            handleClose(); //close the dialog after successful submission
            AboutList();
            setAlt();
            
        })
        .catch(error => {
            console.log(error);
        });
    }

    function handleDelete(data) {
      window.alert('Are you sure you want to delete this Instruction Section?');
      console.log(data.original.id);
      console.log("this is delete inside");
      request({url:`${DELETE_INSTRUCTIONS}${data.original.id}`,method:"delete"}).then(res=>{
        console.log(res);
        AboutList();
      }).catch(error=>{
  console.log(error);
      });
      }

    const AboutList=() =>{
      axios.get(BASE_URL+GET_INSTRUCTIONS)
      .then((res)=>{
          setResponse(res.data)
         
            console.log("disfjiosdfjiodjfiodjfjj"+res.data[0].imageurl);
          // setDateres(res.data.map((item) => item.herosectiondescription))
          console.log("step 1" +res.data.data);
          console.log("step 2" +JSON.stringify(res.data) );
          console.log("step 3" +res);
        //   if (res.data.length === 0) {
        //     setButtonDisabled(true);
        //   } else {
        //     setButtonDisabled(false);
        //   }
      })
      .catch((e) =>{
          console.log(e)
          // console.log('tinymce:', tinymce);
  
      }) 
  }
  useEffect(()=>{
      AboutList();
  },[])

  const handleClickOpen = () => {
    setOpen(true);
      
  };
  
  const handleClose = () => {
    setSelectedRowId(null);
    setOpen(false);
    setEditorres('');
    setFileEdit(null);
    
  
  };
  const handleEditor= (event, editor) => {
    const data = editor.getData();
    setEditorres(data);
  };
  const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'imageUpload',
        'mediaEmbed',
        'undo',
        'redo'
    ],
  };
  
  const handleAddClose =()=>{
    setOpen(false);
    reset();
        setFile(null);
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
   
    setEditorData(data);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  
    const columns = useMemo(
      (rowData) => [
        {
          accessorKey:'id',
          header:'Id',
          enableHiding:false
        },
        {
          accessorKey: 'topic',
          header: 'Topic',
          accessorFn:(row)=>row?row.topic:''
        
        },
       
        {
          accessorKey: 'imgAlternative',
          header: 'Alternative Image Name',
          accessorFn:(row)=>row?row.imgAlternative:''
        },
        
        
        
      
        {
          accessorKey: 'imageurl',
          header: 'Image',
          accessorFn:(row)=>row?row.imageurl:'',
         
        },
      
        ],
      []
    );
  return (
    <div className="mt-3">
         <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}>
        Add
      </Button>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
       
      </MuiAlert>
    </Snackbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
        <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
        <DialogContent>
        <Form onSubmit={handleSubmit(handleAddSubmit)}>
            <Form.Group>
            <Controller
  name="topic"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
    minLength: { value: 3, message: 'Topic should be at least 3 characters long' },
    // maxLength: { value: 250, message: 'Topic should not exceed 250 characters' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic"
      margin='dense'
      error={Boolean(errors?.topic?.message)}
      helperText={errors?.topic?.message}
     
    />
  )}
/>
            </Form.Group>

            <Form.Group>
                  </Form.Group>
        
                  <Form.Group>
            <Form.Label>
                <h4>Description:</h4>
            <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={editorData}
                onChange={handleEditorChange}
                name="editorData"
            />
            </Form.Label>
            <Form.Group>
  <InputLabel>Order</InputLabel>
  <Select
    value={selectedOrder}
    onChange={(e) => setSelectedOrder(e.target.value)}
    fullWidth
    margin="dense"
    label="Order"
  >
    <MenuItem value={1}>1</MenuItem>
    <MenuItem value={2}>2</MenuItem>
    <MenuItem value={3}>3</MenuItem>
    {/* Add more menu items as needed */}
  </Select>
</Form.Group>

                    </Form.Group>
                    <Controller
  name="imgAlternative"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Alternative Image Name  is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Alternative Image Name  is required',
    },
    // pattern: {
    //   value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
    //   message: 'Topic should contain only alphabetic characters',
    // },
    minLength: { value: 2, message: 'Please provide a minimum of 2 characters.' },
    maxLength: { value: 250, message: "Alternative Image Name can't exceed 250 characters." },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="AlterNative Image"
      margin='dense'
      error={Boolean(errors?.imgAlternative?.message)}
      helperText={errors?.imgAlternative?.message}
     
    />
  )}
/>
        
            <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <>
            <div {...getRootProps()}>
              <p>Drag and drop your file here, or click to select a file</p>
            </div>
            <input {...getInputProps()} />
            {file && (
              <p>File uploaded: {file.name}</p>
            )}
          </>
        )}
      </Dropzone>
      <DialogActions>
        <Button onClick={handleAddClose}>Cancel</Button>
    <Button type="submit" color="primary" >
      Submit
    </Button>
        </DialogActions>
          </Form>
      
        </DialogContent>
       
      </Dialog>
           <MaterialReactTable 
      columns={columns} 
      data={response}
   
      initialState={{ columnVisibility: { id: false } }}
      enableRowActions
      enableColumnFilters={false}
   
      renderRowActionMenuItems={({ row }) => [
       
    <>
    <MenuItem onClick={() => handleEdit(row)}> <Edit/> &nbsp;&nbsp;&nbsp;
    Edit</MenuItem>
         <IconButton color="error" onClick={() => handleDelete(row)} >
                
           <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
          </IconButton>
          </>
      
      ]}

      
      />

<Dialog open={selectedRowId !== null} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
        <DialogTitle id="draggable-dialog-title">Edit</DialogTitle>
        <DialogContent>
        <Form >
        <TextField
              fullWidth
              label="Topic"
              margin="dense"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}/>

            <Form.Group>
                  </Form.Group>
                

        
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={editorres}
          onChange={handleEditor}
          name="herosectiondescription"
        />
      </Form.Group>

      <TextField
              fullWidth
              label="Alternative Image Name"
              margin="dense"
              value={alt}
              onChange={(e) => setAlt(e.target.value)}/>
        
      <Dropzone onDrop={onDropEdit}>
    {({ getRootProps, getInputProps }) => (
      <>
        <div {...getRootProps()}>
          <p>Drag and drop your file here, or click to select a file</p>
        </div>
        <input {...getInputProps()} />
        {fileEdit && (
          <p>File uploaded: {fileEdit.name}</p>
        )}
      </>
    )}
  </Dropzone>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleEdits}>Save</Button>
        </DialogActions>
          </Form>
      
        </DialogContent>
       
      </Dialog>
  
       
      </div>
  )
}

export default Instructions