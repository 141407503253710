import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { ADD_MAINVIEWSFEEDBACK } from "ApplicationURI";
import { GET_MAINVIEWSFEEDBACK_DETAILS } from "ApplicationURI";
import { useForm, Controller } from 'react-hook-form';
import { EDIT_MAINVIEWSFEEDBACK } from "ApplicationURI";
import { DELETE_MAINVIEWSFEEDBACK } from "ApplicationURI";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function MainViewsFeedback() {
  const [response,setResponse] = useState([])
  const [open, setOpen] = useState(false);
  const { control, handleSubmit,reset, formState: { errors }} = useForm( );
  const [errorMessage, setErrorMessage] = useState('');
const [openSnackbar, setOpenSnackbar] = useState(false);
 

  const handleAddSubmit=(data)=>{
    console.log("this is adddddddd");
    // e.preventDefault();
    const postData={
      topic:data.topic,
      description:data.description,
      heading:data.heading
    }
    console.log("thiss is adddd data"+JSON.stringify(data) );

    request({url:ADD_MAINVIEWSFEEDBACK, method:"post", data:postData }).then(res=>{
      console.log(res);
      if(res.status===200){
      handleAddClose();
      MainViewsFeedbackList();
      reset();

          }
    }).catch(error=>{
      console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
  }

  const handleSaveRow = ({ row, values,exitEditingMode}) => {
    const requiredFields= ['topic','description','heading'];
    const missingFields = requiredFields.filter((field) =>
      !values[field]);
      if(missingFields.length > 0){
        alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
  return;
      }

    console.log("aishuuuuuuuuuuu testtttttttt" + row.original.id);
    console.log(values);
    if (!values) {
      return;
    }
  const data={
    topic:values.topic,
    description:values.description,
    heading:values.heading
  }
       
    request({url:`${EDIT_MAINVIEWSFEEDBACK}${row.original.id}`, method:"put", data:data}).then(res=>{
        
        const updatedData = [...response]
        updatedData[row.index] = res.data
        setResponse(updatedData)
        exitEditingMode();
     
      })
      .catch(error => {
        console.log(error)
      })
  }

  function handleDelete(data) {
    window.alert('Are you sure you want to delete this Views&Feedback?');
    console.log(data.original.id);
    console.log("this is delete inside");
    request({url:`${DELETE_MAINVIEWSFEEDBACK}${data.original.id}`,method:"delete"}).then(res=>{
      console.log(res);
      MainViewsFeedbackList();
    }).catch(error=>{
console.log(error);
    });
    }


    const handleClickOpen = () => {
      setOpen(true);
        
    };
    
    const handleClose = () => {
      setOpen(false);
      
    
    };
    
    const handleAddClose =()=>{
      setOpen(false);
      reset()
    }
    const handleSnackbarClose = () => {
      setOpenSnackbar(false);
    };

    const MainViewsFeedbackList=() =>{
      axios.get(BASE_URL+GET_MAINVIEWSFEEDBACK_DETAILS)
      .then((res)=>{
          setResponse(res.data)
          // setDateres(res.data.map((item) => item.herosectiondescription))
          console.log("step 1" +res.data.data);
          console.log("step 2" +JSON.stringify(res.data) );
          console.log("step 3" +res);
      })
      .catch((e) =>{
          console.log(e)
          // console.log('tinymce:', tinymce);
  
      }) 
  }
  useEffect(()=>{
    MainViewsFeedbackList();
  },[])
  
    const columns = useMemo(
      (rowData) => [
        {
          accessorKey:'id',
          header:'Id',
          enableHiding:false
        },
        {
          accessorKey: 'heading',
          header: 'Heading',
          accessorFn:(row)=>row?row.heading:''
        
        },
        {
            accessorKey: 'topic',
            header: 'Topic',
            accessorFn:(row)=>row?row.topic:''
          
          },
          {
            accessorKey: 'description',
            header: 'Description',
            accessorFn:(row)=>row?row.description:''
          
          },
             
      
        ],
      []
    );
    return (
      
      <div className="mt-3">
      <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}>
     Add
   </Button>
   <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
   <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
maxWidth="md">
     <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
     <DialogContent>
     <Form onSubmit={handleSubmit(handleAddSubmit)}>
     <Form.Group>
     <Controller
  name="heading"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Heading is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Heading is required',
    },
    minLength: { value: 3, message: 'Heading should be at least 3 characters long' },
    // maxLength: { value: 50, message: 'Heading should not exceed 50 characters' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Heading"
      margin='dense'
      error={Boolean(errors?.heading?.message)}
      helperText={errors?.heading?.message}
     
    />
  )}
/>
         </Form.Group>
         <Form.Group>
         <Controller
  name="topic"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Topic is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Topic is required',
    },
    minLength: { value: 3, message: 'Topic should be at least 3 characters long' },
    // maxLength: { value: 250, message: 'Topic should not exceed 250 characters' },
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Topic"
      margin='dense'
      error={Boolean(errors?.topic?.message)}
      helperText={errors?.topic?.message}
     
    />
  )}
/>
         </Form.Group>
              
     
         <Form.Group>
         <Controller
  name="description"
  control={control}
  defaultValue=""
  rules={{
    required: { value: true, message: 'Description is required' },
    validate: {
      hasNoWhitespace: value => value.trim() !== '' || 'Description is required',
    },
    minLength: { value: 3, message: 'Description should be at least 3 characters long' },
   
  }}
  // validate={value => value.trim()}
  render={({ field }) => (
    <TextField
      fullWidth
      {...field}     
      label="Description"
      margin='dense'
      error={Boolean(errors?.description?.message)}
      helperText={errors?.description?.message}
     
    />
  )}
/>
         </Form.Group>
         <DialogActions>
     <Button onClick={handleAddClose}>Cancel</Button>
 <Button type="submit" color="primary" >
   Submit
 </Button>
     </DialogActions>
       </Form>

     </DialogContent>
    
   </Dialog>
        <MaterialReactTable 
   columns={columns} 
   data={response}
   editingMode="row"
   enableEditing
   initialState={{ columnVisibility: { id: false } }}
   enableRowActions
   enableColumnFilters={false}

   onEditingRowSave={handleSaveRow}

   renderRowActionMenuItems={({ row }) => [
    
 
      <IconButton color="error" onClick={() => handleDelete(row)} >
        <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
       </IconButton>
   
   ]}
   
   />

    
   </div>
    );
  };

export default MainViewsFeedback