// export const BASE_URL="http://10.64.26.139:8099/api/v1/";
// export const LOGIN_BASE_URL="http://10.64.26.139:8099/api/v1/auth/";

// export const BASE_URL= "http://localhost:1277/api/v1/";
// export const LOGIN_BASE_URL="http://localhost:1277/api/v1/auth/";

// export const BASE_URL= "http://10.64.26.140:8099/api/v1/";
// export const LOGIN_BASE_URL="http://10.64.26.140:8099/api/v1/auth/";

// export const BASE_URL= "http://localhost:8099/api/v1/";
// export const LOGIN_BASE_URL="http://localhost:8099/api/v1/auth/";


export const BASE_URL="https://api.hybridhoa.com/hhoa-home/api/v1/";


// export const BASE_URL = "http://10.64.16.169:7070/hhoa-home/api/v1/";
// export const LOGIN_BASE_URL = "http://10.64.16.169:7070/hhoa-home/api/v1/auth/";


export const LOGIN_BASE_URL ="https://api.hybridhoa.com/hhoa-home/api/v1/auth/";


export const GET_HERO_DETAILS = "hero";
export const EDIT_HERO = "hero/";
export const DELETE_HERO = "hero/";

export const GET_ABOUT_DETAILS = "about";
export const ADD_ABOUT = "about";
export const EDIT_ABOUT = "about/";
export const DELETE_ABOUT = "about/";

export const GET_MAINFEATURE_DETAILS = "featurecategory";
export const ADD_MAINFEATURE = "featurecategory";
export const EDIT_MAINFEATURE = "featurecategory/";
export const DELETE_MAINFEATURE = "featurecategory/";

export const GET_SUBFEATURE_DETAILS = "featurecard";
export const ADD_SUBFEATURE = "featurecard";
export const EDIT_SUBFEATURE = "featurecard/";
export const DELETE_SUBFEATURE = "featurecard/";
export const GET_ALL_SUBFEATURE_BY_ID = "featurecard/";

export const GET_VIEWSFEEDBACK_DETAILS = "fbcard";
export const ADD_VIEWSFEEDBACK = "fbcard";
export const EDIT_VIEWSFEEDBACK = "fbcard/";
export const DELETE_VIEWSFEEDBACK = "fbcard/";

export const GET_MAINVIEWSFEEDBACK_DETAILS = "fb";
export const ADD_MAINVIEWSFEEDBACK = "fb";
export const EDIT_MAINVIEWSFEEDBACK = "fb/";
export const DELETE_MAINVIEWSFEEDBACK = "fb/";

export const GET_BLOG_DETAILS = "blog";
export const ADD_BLOG = "blog";
export const EDIT_BLOG = "blog/";
export const DELETE_BLOG = "blog/";
export const GET_ALL_BLOG_BY_ID = "blog/";

export const GET_FAQ_DETAILS = "faq";
export const ADD_FAQ = "faq";
export const EDIT_FAQ = "faq/";
export const DELETE_FAQ = "faq/";

export const GET_SUBFAQ_DETAILS = "faqcard";
export const ADD_SUBFAQ = "faqcard";
export const EDIT_SUBFAQ = "faqcard/";
export const DELETE_SUBFAQ = "faqcard/";

export const GET_PRICING_HERO = "pricinghero";
export const ADD_PRICING_HERO = "pricinghero";
export const EDIT_PRICING_HERO = "pricinghero/";
export const DELETE_PRICING_HERO = "pricinghero/";
export const GET_PRICING_HERO_BY_ID = "pricinghero/";

export const GET_PRICING_CONTENT = "pricingcontent";
export const ADD_PRICING_CONTENT = "pricingcontent";
export const EDIT_PRICING_CONTENT = "pricingcontent/";
export const DELETE_PRICING_CONTENT = "pricingcontent/";
export const GET_CONTENT_BY_ID = "pricingcontent/";

export const GET_TIMELINE = "timeline";
export const ADD_TIMELINE = "timeline";
export const EDIT_TIMELINE = "timeline/";
export const DELETE_TIMELINE = "timeline/";

export const PRIVACY_POLICY = "privacypolicy";
export const ADD_PRIVACY_POLICY = "privacypolicy";
export const GET_PRIVACY_BY_ID_US = "privacypolicy/";
export const GET_PRIVACY_BY_ID_IN = "privacypolicy/";
export const EDIT_PRIVACY = "privacypolicy/";
export const DELETE_PRIVACY = "privacypolicy/";

export const TERMS_CONDITIONS = "securitypolicy";
export const ADD_TERMS_CONDITIONS = "securitypolicy";
export const GET_TERMS_BY_ID_US = "securitypolicy/";
export const GET_TERMS_BY_ID_IN = "securitypolicy/";
export const EDIT_TERMS = "securitypolicy/";
export const DELETE_TERMS = "securitypolicy/";

export const ADD_INSTRUCTIONS = "instructions";
export const GET_INSTRUCTIONS = "instructions";
export const GET_INSTRUCTIONS_BY_ID = "instructions/";
export const UPDATE_INSTRUCTIONS = "instructions/";
export const DELETE_INSTRUCTIONS = "instructions/";

export const ADD_SUBINSTRUCTIONS = "subinstructions";
export const GET_SUBINSTRUCTIONS = "subinstructions";
export const GET_SUBINSTRUCTIONS_BY_ID = "subinstructions/";
export const UPDATE_SUBINSTRUCTIONS = "subinstructions/";
export const DELETE_SUBINSTRUCTIONS = "subinstructions/";

export const ADD_FEATURES_NOTIFICATIONS = "popup";
export const GET_FEATURES_NOTIFICATIONS = "popup";
export const GET_FEATURES_NOTIFICATIONS_BY_ID = "popup/";
export const UPDATE_FEATURES_NOTIFICATIONS = "popup/";
export const DELETE_FEATURES_NOTIFICATIONS = "popup/";

export const IMAGE = "image/";
export const CONTACT = "contact";

export const SUBSCRIBE = "subscribe";
export const DASHBOARD = "dashboard";
export const SIGNIN = "login";

export const DATA_COLLECTION_AND_SECURITY="/datacollection";
export const DATA_COLLECTION_AND_SECURITY_BY_ID = "datacollection/";
export const EDIT_DATA_COLLECTION_AND_SECURITY_BY_ID = "datacollection/";
export const ALL_DATA_COLLECTION_AND_SECURITY_BY_ID = "datacollection";
export const DELETE_DATA_COLLECTION_AND_SECURITY_BY_ID = "datacollection/";