
import { MdAnnouncement,  MdContactPage,  MdOutline6KPlus, MdOutlineChat, MdOutlineStar } from "react-icons/md";


import Widget from "components/widget/Widget";

import { useEffect, useState } from "react";
import { request } from "components/axiosconfig";
import { DASHBOARD } from "ApplicationURI";
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [response,setResponse] = useState([])
  let navigate= useNavigate()
  const SubscribeList=() =>{
    request({url:DASHBOARD,method:"get"}).then((res)=>{
        setResponse(res.data)
        // setDateres(res.data.map((item) => item.herosectiondescription))
        console.log("step 1" +res.data.data);
        console.log("step 2" +JSON.stringify(res.data) );
        console.log("step 3" +res);
    })
    .catch((e) =>{
        console.log(e)
        // console.log('tinymce:', tinymce);

    }) 
}
useEffect(()=>{
  SubscribeList();
},[])

useEffect(()=>{
  console.log(localStorage.getItem("token")==="");
  if(localStorage.getItem("token")===undefined){
    navigate("/")
  }
},[navigate])
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdOutlineStar className="h-7 w-7" />}
          title={"Feature Card"}
          subtitle={response.feature}
       
         
        />
        <Widget
          icon={<MdOutline6KPlus className="h-6 w-6" />}
          title={"Views & FeedBack Card"}
          subtitle={response.viewAndFeedback}
        />
        <Widget
          icon={<MdOutlineChat className="h-7 w-7" />}
          title={"Blog"}
          subtitle={response.blog}
        />
        <Widget
          icon={<MdAnnouncement className="h-6 w-6" />}
          title={"Faq Card"}
          subtitle={response.faq}
        />
         <Widget
          icon={<MdContactPage className="h-6 w-6" />}
          title={"Contact"}
          subtitle={response.contact}
        />
      
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          {/* <DailyTraffic /> */}
          {/* <PieChartCard />
          <MiniCalendar /> */}
        </div>

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
           
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
