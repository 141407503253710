import { useEffect, useMemo,  useState } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle,  MenuItem } from "@mui/material";
import { Form } from "react-bootstrap";
import { request } from "components/axiosconfig";
import { Delete, Edit } from "@mui/icons-material";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TERMS_CONDITIONS } from "ApplicationURI";
import { ADD_TERMS_CONDITIONS } from "ApplicationURI";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { EDIT_TERMS } from "ApplicationURI";
import { DELETE_TERMS } from "ApplicationURI";
import { GET_TERMS_BY_ID_US } from "ApplicationURI";
function TermsCondition() {
    const [response,setResponse] = useState([])
    const [open, setOpen] = useState(false);


    const [editorData, setEditorData] = useState('');
    const [updateRes,setUpdateRes] = useState([])
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

   
    const [editorres,setEditorres]=useState('');
 
    const [selectedRowId, setSelectedRowId] = useState(null);

    const striptags = require('striptags');
    

    const selectedRow = updateRes;




    const editorConfiguration = {
        toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'indent',
            'outdent',
            '|',
            'blockQuote',
            'insertTable',
            'imageUpload',
            'mediaEmbed',
            'undo',
            'redo'
        ],
      };
  
 
  

    const handleEditor= (event, editor) => {
        const data = editor.getData();
        setEditorres(data);
      };
  
 

      const TermsConditionListById = (id) => {
        axios.get(`${BASE_URL}${GET_TERMS_BY_ID_US}${id}`)
          .then((res) => {
              console.log("iddd"+selectedRow)
            setUpdateRes(res.data.id);
            console.log("step 1" + res.data.data);
            console.log("step 2" + JSON.stringify(res.data));
            console.log("step 3" + res);
            setEditorres(res.data.data);
           
          })
          .catch((e) => {
            // handle error
          });
      };

        
      const handleEdit = (selectedRow) => {
        console.log('selectedRow:', selectedRow); // check the value of selectedRow
        console.log('selectedRow.id:', selectedRow.id); // check the value of selectedRow.id
        setSelectedRowId(selectedRow.original.id);
        TermsConditionListById(selectedRow.original.id);
      };
      
    // useEffect(() => {
    //     HeroListById()
    //   }, [])

      const handleEdits = (data) => {
        console.log("Button clicked  in editttt");
        console.log(selectedRow+"selected");
        // console.log(JSON.stringify(row)  +"testttttt")
   
 
        // e.preventDefault();
        // const formData = new FormData();
        // formData.append('data',editorres)                           
        // const headers = {
        //     'Content-Type': 'multipart/form-data'
        //   };
        if(!editorres){
          alert('Please provide description');
          return;
        }
        const postData={
            data:editorres
           }
             
          request({url:`${EDIT_TERMS}${selectedRow}`, method:"put", data:postData}).then(res=>{
     
            handleClose(); //close the dialog after successful submission
            TermsConditionList()
        })
        .catch(error => {
            console.log(error);
        });
    }
  
    
  
    const handleAddSubmit=(data)=>{
    //   e.preventDefault();
    //   const formData = new FormData();
    //   formData.append('data',editorData)
    if(!editorData){
      alert('Please provide description');
      return;
    }
  
   const postData={
    data:editorData
   }
  
      request({url:ADD_TERMS_CONDITIONS, method:"post", data:postData}).then(res=>{
        console.log(res);
        if(res.status===200){
          handleAddClose();
          TermsConditionList();
          setEditorData('')
          // setFile(null)
              
           
  
            }
      }).catch(error=>{
        console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {
        
        console.log(error.request);
      } else {
      
        console.log('Error', error.message);
      }
    });
    }
  
  
  
    const TermsConditionList=() =>{
      axios.get(`${BASE_URL}${TERMS_CONDITIONS}`)
      .then((res)=>{
        console.log(res.data.length)
        if (Array.isArray(res.data) && res.data.length !== 0) {
                  setResponse([res.data[0]]) // Set data from the first item in the array
                } else {
                  setResponse(''); // Set empty string if the data is not as expected
                }
         
          console.log("step 1" +res.data.data);
          console.log("step 2" +JSON.stringify(res.data) );
          console.log("step 3" +res);
          console.log("length check"+res.data.length);
          if (res.data.length === 0) {
            setButtonDisabled(false);
          } else {
            setButtonDisabled(true);
          }
      })
      .catch((e) =>{
          console.log(e)
          
  
      }) 
  }
  useEffect(()=>{
    TermsConditionList();
  },[])

//   function handleDelete(data) {
//     window.alert('Are you sure you want to delete this Terms&Condition?');
//     console.log(data.original.id);
//     console.log("this is delete inside");
//     request({url:`${DELETE_TERMS}${data.original.id}`,method:"delete"}).then(res=>{
//       console.log(res);
//       TermsConditionList();
//     }).catch(error=>{
// console.log(error);
//     });
//     }

function handleDelete(data) {
  const isConfirmed = window.confirm('Are you sure you want to delete this Terms&Condition?');

  if (isConfirmed) {
    console.log(data.original.id);
    // console.log("This is delete inside");

    request({ url: `${DELETE_TERMS}${data.original.id}`, method: "delete" })
      .then(res => {
        // console.log(res);
        TermsConditionList();
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    // console.log('Deletion canceled.');
  }
}
  const handleClickOpen = () => {
    setOpen(true);
      
  };
  
  const handleClose = () => {
    setSelectedRowId(null);
    setOpen(false);
    setEditorres('');
    // setFileEdit(null);
  };
  
  const handleAddClose =()=>{
    setOpen(false);
    setEditorData('')
    // setFile(null)
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
 

    const columns = useMemo(
        (rowData) => [
          {
            accessorKey:'id',
            header:'Id',
            enableHiding:false
          },
          {
            accessorKey: 'data',
            header: 'Topic',
            accessorFn:(row)=>striptags(row.data)
          
          },
          
        
          ],
        [striptags]
      );
  return (
    <div className="mt-3">
      
    <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}  disabled={isButtonDisabled} >
     Add
   </Button>
   <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }}
  style={{width:"100%"}}
>
      <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
        {errorMessage}
      </MuiAlert>
    </Snackbar>
   <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
maxWidth="md">
     <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
     <DialogContent>
     <Form>
              
         <Form.Group>
            <Form.Label>
                <h4>Description:</h4>
            <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={editorData}
                onChange={handleEditorChange}
                name="editorData"
            />
            </Form.Label>
          
                    </Form.Group>

       </Form>

     </DialogContent>
     <DialogActions>
     <Button onClick={handleAddClose}>Cancel</Button>
 <Button type="submit" color="primary" onClick={handleAddSubmit}>
   Submit
 </Button>
     </DialogActions>
   </Dialog>
      <MaterialReactTable 
 columns={columns} 
 data={response}
//  editingMode="row"
//  enableEditing
//  onEditingRowSave={handleSaveRow}

 renderRowActionMenuItems={({ row }) => [
    <>
  
    <MenuItem onClick={() => handleEdit(row)}> <Edit/> &nbsp;&nbsp;&nbsp;
    Edit</MenuItem>
    <MenuItem color="error" onClick={() => handleDelete(row)} >
      <Delete style={{color:'red'}} />  &nbsp;&nbsp;&nbsp; Delete
     </MenuItem>
     </>
 
 ]}

 initialState={{ columnVisibility: { id: false } }}
 enableRowActions
 enableColumnFilters={false}

 />

<Dialog open={selectedRowId !== null} onClose={handleClose} fullWidth
  maxWidth="md">
  <DialogTitle>Edit Row {selectedRow}</DialogTitle>
  <DialogContent>
    <Form>  
      <Form.Group>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={editorres}
          onChange={handleEditor}
          name="herosectiondescription"
        />
      </Form.Group>

     </Form>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Cancel</Button>
    <Button onClick={handleEdits}>Save</Button>
  </DialogActions>
</Dialog>

  
 </div>
  )
}

export default TermsCondition