import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOGIN_BASE_URL } from "ApplicationURI";
import { SIGNIN } from "ApplicationURI";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Form } from "react-bootstrap";
import { Button, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

export default function SignIn() {
  let navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    console.log(localStorage.getItem("token") == undefined);
    if (localStorage.getItem("token") != undefined) {
      console.log("enpty token");
      navigate("/admin/default");
    }
  }, []);

  const handleClick = (data) => {
    const postData = {
      email: data.email,
      password: data.password,
    };
    console.log(data);

    axios
      .post(`${LOGIN_BASE_URL}${SIGNIN}`, postData)
      .then((response) => {
        console.log(response.data.data);
        if (response.status === 200) {
          console.log(response);
          console.log(
            response.status + "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr "
          );
          console.log(
            response.data.token +
              "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr "
          );
          console.log(
            response.message + "ggggggggggggggggggggggggggggggggggggggggggg"
          );
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          navigate("/admin", { replace: true });
        }
      })
      .catch((error) => {
        console.log(error.response); // Log the error response object
        if (error.response) {
          // Request was made and server responded with a status code
          console.log("error.response.status" + error.response.status);
          console.log("fhgfhgf" + error.response.data.message);

          if (error.response.status === 400) {
            setErrorMessage(error.response.data.message);
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ width: "100%" }}
        >
          <MuiAlert
            severity="error"
            onClose={handleSnackbarClose}
            style={{ width: "50%" }}
          >
            {errorMessage}
          </MuiAlert>
        </Snackbar>

        <Form onSubmit={handleSubmit(handleClick)}>
          <Form.Group>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: "Email is required" },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Please enter a valid email address",
                },
              }}
              // validate={value => value.trim()}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="Email"
                  margin="dense"
                  error={Boolean(errors?.email?.message)}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </Form.Group>

          <Form.Group>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: "Password is required" },
                validate: {
                  hasNoWhitespace: (value) =>
                    value.trim() !== "" || "Password is required",
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="Password"
                  type="password"
                  margin="dense"
                  error={Boolean(errors?.password?.message)}
                  helperText={errors?.password?.message}
                />
              )}
            />
          </Form.Group>

          {/* <Button onClick={handleAddClose}>Cancel</Button> */}
          <Button type="submit" color="primary" variant="contained">
            Login
          </Button>
        </Form>

        {/* <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          value={addmail}
          onChange={(event) => setAddMail(event.target.value)}
        />

      
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={pass}
          onChange={(event) => SetPass(event.target.value)}
        />
        
        <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" type="submit" onClick={handleClick}>
          Sign In
        </button> */}
      </div>
    </div>
  );
}
