import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { ADD_VIEWSFEEDBACK } from "ApplicationURI";
import { useForm, Controller } from 'react-hook-form';
import { CONTACT } from "ApplicationURI";

function Contact() {
    const [response,setResponse] = useState([])
    const [open, setOpen] = useState(false);
    
    const { control, handleSubmit, formState: { errors }} = useForm( );
   
  
    const handleAddSubmit=(data)=>{
      console.log("this is adddddddd");
      // e.preventDefault();
      const postData={
        rating:data.rating,
        feedBack:data.feedBack,
        name:data.name,
        designation:data.designation
      }
      console.log("thiss is adddd data"+JSON.stringify(data) );
  
      request({url:ADD_VIEWSFEEDBACK, method:"post", data:postData }).then(res=>{
        console.log(res);
        if(res.status===200){
        handleAddClose();
        viewsandFeedback();
  
            }
      }).catch(error=>{
        console.log(error);
      });
    }

    
    const viewsandFeedback=() =>{
      request({url:CONTACT,method:"get"}).then((res)=>{
          setResponse(res.data)
          // setDateres(res.data.map((item) => item.herosectiondescription))
          console.log("step 1" +res.data.data);
          console.log("step 2" +JSON.stringify(res.data) );
          console.log("step 3" +res);
      })
      .catch((e) =>{
          console.log(e)
          // console.log('tinymce:', tinymce);
  
      }) 
  }
  useEffect(()=>{
    viewsandFeedback();
  },[])
  
    // const handleSaveRow = ({ row, values,exitEditingMode}) => {
    //   const requiredFields= ['rating','feedBack','name','designation'];
    //   const missingFields = requiredFields.filter((field) =>
    //     !values[field]);
    //     if(missingFields.length > 0){
    //       alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
    // return;
    //     }
  
    //   console.log("aishuuuuuuuuuuu testtttttttt" + row.original.id);
    //   console.log(values);
    //   if (!values) {
    //     return;
    //   }
    // const data={
    //  rating:values.rating,
    //  feedBack:values.feedBack,
    //  name:values.name,
    //  designation:values.designation
    // }
         
    //   request({url:`fbcard/${row.original.id}`, method:"put", data:data}).then(res=>{
          
    //       const updatedData = [...response]
    //       updatedData[row.index] = res.data
    //       setResponse(updatedData)
    //       exitEditingMode();
       
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }
  
    function handleDelete(data) {
      window.alert('Are you sure you want to delete this Contact?');
      console.log(data.original.id);
      console.log("this is delete inside");
      request({url:`contact/${data.original.id}`,method:"delete"}).then(res=>{
        console.log(res);
        viewsandFeedback();
      }).catch(error=>{
  console.log(error);
      });
      }
  
  
      // const handleClickOpen = () => {
      //   setOpen(true);
          
      // };
      
      const handleClose = () => {
        setOpen(false);
        
      
      };
      
      const handleAddClose =()=>{
        setOpen(false);
      }
  
  
    
      const columns = useMemo(
        (rowData) => [
          {
            accessorKey:'id',
            header:'Id',
            enableHiding:false
          },
          {
            accessorKey: 'fullName',
            header: 'Full Name',
            accessorFn:(row)=>row?row.fullName:''
          
          },
          {
              accessorKey: 'email',
              header: 'Email',
              accessorFn:(row)=>row?row.email:''
            
            },
            {
              accessorKey: 'phone',
              header: 'Phone',
              accessorFn:(row)=>row?row.phone:''
            
            },
            {
              accessorKey: 'category',
              header: 'Category',
              accessorFn:(row)=>row?row.category:''
            
            },
            {
              accessorKey: 'refer',
              header: 'Refer',
              accessorFn:(row)=>row?row.refer:''
            
            },
            {
              accessorKey: 'referDesc',
              header: 'Refer Desc',
              accessorFn:(row)=>row?row.referDesc:''
            
            },
            {
                accessorKey: 'message',
                header: 'Message',
                accessorFn:(row)=>row?row.message:''
              
              }
         
        
          ],
        []
      );
      return (
        
        <div className="mt-3">
        {/* <Button variant="contained"  style={{float:'left',marginTop:20,marginLeft:20}} onClick={handleClickOpen}>
       Add
     </Button> */}
     <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title"   fullWidth
  maxWidth="md">
       <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
       <DialogContent>
       <Form onSubmit={handleSubmit(handleAddSubmit)}>
       <Form.Group>
       <Controller
    name="rating"
    control={control}
    defaultValue=""
    rules={{
      required: { value: true, message: 'Rating is required' },
      pattern: {
        value: /^[1-5]$/,
        message: 'Rating should be a number between 1-5',
      },
      minLength: { value: 1, message: 'Rating should be at least 1 characters long' },
      maxLength: { value: 1, message: 'Rating should not exceed 1 characters' },
    }}
    // validate={value => value.trim()}
    render={({ field }) => (
      <TextField
        fullWidth
        {...field}     
        label="Rating"
        margin='dense'
        error={Boolean(errors?.rating?.message)}
        helperText={errors?.rating?.message}
       
      />
    )}
  />
           </Form.Group>
           <Form.Group>
           <Controller
    name="feedBack"
    control={control}
    defaultValue=""
    rules={{
      required: { value: true, message: 'FeedBack is required' },
      pattern: {
        value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
        message: 'FeedBack should contain only alphabetic characters',
      },
      minLength: { value: 3, message: 'FeedBack should be at least 3 characters long' },
     
    }}
    // validate={value => value.trim()}
    render={({ field }) => (
      <TextField
        fullWidth
        {...field}     
        label="FeedBack"
        margin='dense'
        error={Boolean(errors?.feedBack?.message)}
        helperText={errors?.feedBack?.message}
       
      />
    )}
  />
           </Form.Group>
                
       
           <Form.Group>
           <Controller
    name="name"
    control={control}
    defaultValue=""
    rules={{
      required: { value: true, message: 'Name is required' },
      pattern: {
        value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
        message: 'Name should contain only alphabetic characters',
      },
      minLength: { value: 3, message: 'Name should be at least 3 characters long' },
      maxLength: { value: 50, message: 'Name should not exceed 50 characters' },
     
    }}
    // validate={value => value.trim()}
    render={({ field }) => (
      <TextField
        fullWidth
        {...field}     
        label="Name"
        margin='dense'
        error={Boolean(errors?.name?.message)}
        helperText={errors?.name?.message}
       
      />
    )}
  />
           </Form.Group>
           <Form.Group>
           <Controller
    name="designation"
    control={control}
    defaultValue=""
    rules={{
      required: { value: true, message: 'Designation is required' },
      pattern: {
        value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
        message: 'Designation should contain only alphabetic characters',
      },
      minLength: { value: 3, message: 'Designation should be at least 3 characters long' },
     
    }}
    // validate={value => value.trim()}
    render={({ field }) => (
      <TextField
        fullWidth
        {...field}     
        label="Designation"
        margin='dense'
        error={Boolean(errors?.designation?.message)}
        helperText={errors?.designation?.message}
       
      />
    )}
  />
           </Form.Group>
           <DialogActions>
       <Button onClick={handleAddClose}>Cancel</Button>
   <Button type="submit" color="primary" >
     Submit
   </Button>
       </DialogActions>
         </Form>
  
       </DialogContent>
       
     </Dialog>
          <MaterialReactTable 
     columns={columns} 
     data={response}
    //  editingMode="row"
    //  enableEditing
     initialState={{ columnVisibility: { id: false } }}
     enableRowActions
     enableColumnFilters={false}
  
    //  onEditingRowSave={handleSaveRow}
  
     renderRowActionMenuItems={({ row }) => [
      
   
        <IconButton color="error" onClick={() => handleDelete(row)} >
          <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
         </IconButton>
     
     ]}
     
     />
  
      
     </div>
      );
    };
  

export default Contact