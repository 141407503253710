import { useEffect, useMemo, useState, useCallback } from "react";
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import { BASE_URL } from "ApplicationURI";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField,  Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { request } from "components/axiosconfig";
import { Delete } from "@mui/icons-material";
import { GET_MAINFEATURE_DETAILS } from "ApplicationURI";

import { ADD_SUBFEATURE } from "ApplicationURI";
import { GET_SUBFEATURE_DETAILS } from "ApplicationURI";
import { useForm, Controller } from 'react-hook-form';
import { EDIT_SUBFEATURE } from "ApplicationURI";
import { DELETE_SUBFEATURE } from "ApplicationURI";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GET_ALL_SUBFEATURE_BY_ID } from "ApplicationURI";

function SubFeature() {
  const [response, setResponse] = useState([])
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [editAlts, setEditAlt] = useState('');
  const [editcat, setEditcat] = useState('');
  const [edittopic, setEdittopic] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [updateRes, setUpdateRes] = useState([])
  const [editorres, setEditorres] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(null);
  const selectedRow = updateRes;
  const [fileEdit, setFileEdit] = useState(null);

  const { control, handleSubmit, reset, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const striptags = require('striptags');
  const [descriptions, setDescriptions] = useState([]);
  const [descriptionTextBoxes, setDescriptionTextBoxes] = useState([]);
  const [FeatureData, setFeatureData] = useState([]);


  const handleAddDescription = () => {
    setDescriptions([...descriptions, '']);
  };

  const handleDeleteDescription = (index) => {
    const newDescriptions = [...descriptions];
    newDescriptions.splice(index, 1);
    setDescriptions(newDescriptions);
  };



 

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // setSelectedFile(file);
    setSelectedFileName(file.name);
  }


  const onDrop = (acceptedFiles, rejectedFiles) => {
    // if (!acceptedFiles[0]) {
    //   alert('Please select a file.');
    //   return;
    // }
    if (rejectedFiles.length > 0 || acceptedFiles.length === 0 || !acceptedFiles[0].type.startsWith('image/')) {
      alert('Please upload a valid image file.');
      return;
    }




    // Do something with the uploaded image file
    console.log(acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  };

  const SubFeatureList = () => {
    axios.get(BASE_URL + GET_SUBFEATURE_DETAILS)
      .then((res) => {
        setResponse(res.data)
        // setDateres(res.data.map((item) => item.herosectiondescription))
        console.log("step 1" + res.data.data);
        console.log("step 2" + JSON.stringify(res.data));
        console.log("step 3" + res);

        const categories = res.data.map(item => item.category);
        console.log("Category values:", categories);
      })
      .catch((e) => {
        console.log(e)
        // console.log('tinymce:', tinymce);

      })
  }
  useEffect(() => {
    SubFeatureList();
  }, [])


  // const Category=()=>{
  //    const value =1 
  //    const forcategory =value.map(item=>)
  // }



  const fetchdata = useCallback(async () => {
    try {
      const responseData = await axios.get(BASE_URL + GET_MAINFEATURE_DETAILS);


      setFeatureData(responseData.data);
      console.log(FeatureData);

    } catch (error) {
      console.error('Error fetching feature data:', error);
    }
  }, [FeatureData]);
  useEffect(() => {
    fetchdata();
  }, [fetchdata]);

  // const onSubmit = (data) => {

  //   console.log(data);
  // };
  const handleClose = () => {
    setSelectedRowId(null);
    setOpen(false);
    setEditorres('');

    setFileEdit(null);
  };

  const onDropEdit = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0 || acceptedFiles.length === 0 || !acceptedFiles[0].type.startsWith('image/')) {
      alert('Please upload a valid image file.');
      return;
    }
    // Do something with the uploaded file

    console.log(acceptedFiles[0] + "fileeeeeeeeeeeeeeeeeeeeeeeeee");
    setFileEdit(acceptedFiles[0]);
  }


  const handleEdit = (selectedRow) => {
    console.log('selectedRow:', selectedRow); // check the value of selectedRow
    console.log('selectedRow.id:', selectedRow.id); // check the value of selectedRow.id
    setSelectedRowId(selectedRow.original.id);
    subFeatureById(selectedRow.original.id);
  };



  const subFeatureById = (id) => {
    axios.get(`${BASE_URL}${GET_ALL_SUBFEATURE_BY_ID}${id}`)
      .then((res) => {
        console.log("iddd" + selectedRow)
        setUpdateRes(res.data.id);
        console.log("step 1" + res.data.data);
        console.log("step 2" + JSON.stringify(res.data));
        console.log("step 3" + res);
        console.log(res.data.category + "category");
        console.log(res.data.topic + "topic");
        const { descriptionList } = res.data;
        // setedit
        setEditAlt(res.data.imgAlternative);
        console.log(res.data.category + "category");
        // setEditcat(res.data.category);
        setEdittopic(res.data.topic);
        // setFeaturesOrder(res.data.featuresOrder);
        setEditorres(descriptionListToString(descriptionList));
        // setImages(res.data.image);

        const descriptionContents = descriptionList ? descriptionList.map(item => item.content) : [];
        setDescriptionTextBoxes(descriptionContents);


        const categoryId = parseInt(res.data.category);





        // Fetch the main feature details
        const mainFeature = FeatureData.find(feature => feature.id === categoryId);

        if (mainFeature) {
          // Log the title of the respective id
          console.log(`Title of id ${mainFeature.id}: ${mainFeature.title}`);

          setEditcat(mainFeature.title)
        } else {
          console.log(`No matching id found in GET_MAINFEATURE_DETAILS for ${categoryId}`);
        }

      })
      .catch((e) => {
        // handle error
      });
  };
  // const getTitleByIdForEdit = (id) => {
  //   const feature = updateRes.find((item) => item.id === id);
  //   // return feature ? feature.title : 'Title not found';
  //   setEditcat(feature.title)

  // };

  const descriptionListToString = (descriptionList) => {
    return descriptionList ? descriptionList.map(item => item.content).join(', ') : '';
  };

  const handleDescriptionChange = (value, index) => {
    // Update the corresponding item in the descriptionList state
    const updatedDescriptions = [...descriptionTextBoxes];
    updatedDescriptions[index] = value;
    setDescriptionTextBoxes(updatedDescriptions);
  };






  // Handler for adding a new text box
  const handleAddTextBox = () => {
    setDescriptionTextBoxes([...descriptionTextBoxes, '']);
  };

  // Handler for removing the last text box
  const handleRemoveTextBox = (index) => {
    if (descriptionTextBoxes.length > 0) {
      const updatedDescriptions = [...descriptionTextBoxes];
      updatedDescriptions.splice(index, 1);
      setDescriptionTextBoxes(updatedDescriptions);
    }
  };


 

  const handleEdits = (e) => {

    console.log("Values to be Sent:", descriptionTextBoxes);
    console.log("Button clicked  in editttt");
    console.log(selectedRow + "selected");
    const emptyFields = [];
    // console.log(JSON.stringify(row)  +"testttttt")
    if (!editorres) {
      emptyFields.push('Description');
    }

    if (!editAlts) {
      emptyFields.push('Alternative Image Name');
    }

    console.log(emptyFields);
    // // Check if any fields are empty
    // if (emptyFields.length > 0) {
    //   const error = `Fields are required: ${emptyFields.join(', ')}`;
    //   alert(error);
    //   // You can display the error message or handle it as needed
    //   return;
    // }

    console.log(descriptionTextBoxes.length);

    if (descriptionTextBoxes.length === 0) {
      alert("Please provide Description");
      return
    }
    for (let i = 0; i < descriptionTextBoxes.length; i++) {
      const currentDescription = descriptionTextBoxes[i];


      if (currentDescription.length < 2) {
        alert(`Description ${i + 1} should contain at least 2 characters`);
        return;
      }

      if (currentDescription.length > 100) {
        alert(`Description ${i + 1} can't exceed 100 characters`);
        return;
      }
    }

    const editAltsTrimmed = editAlts.trim().replace(/\s{2,}/g, ' ');
    if (editAlts.length < 2) {
      alert('Alternative Image Name should contain at least 2 characters');
      return;
    }


    if (editAlts.length > 100) {
      alert("Alternative Image Name can't exceed 100 characters");
      return;
    }

    if (editcat.length < 2) {
      alert('Category  should contain at least 2 characters');
      return;
    }
    if (editcat.length > 100) {
      alert("Category can't exceed 100 characters");
      return;
    }

    const edittopicTrimmed = edittopic.trim().replace(/\s{2,}/g, ' ');

    if (edittopicTrimmed.length < 2) {
      alert('Topic should contain at least 2 characters');
      return;
    }
    if (edittopicTrimmed.length > 50) {
      alert("Topic can't exceed 50 characters");
      return;
    }

    e.preventDefault();
    const formData = new FormData();
    const selectedFeature = FeatureData.find(item => item.title === editcat);
    const categoryId = selectedFeature ? selectedFeature.id : '';  // Assuming id is a string, adjust accordingly
    formData.append('description', descriptionTextBoxes)
    formData.append('image', fileEdit)
    formData.append('imgAlternative', editAltsTrimmed)
    formData.append('category', categoryId)
    formData.append('topic', edittopicTrimmed)
    formData.append('featuresOrder', false)
    console.log("testing" + formData)


    console.log(descriptionTextBoxes + "description in edit");


    //  return;                          
    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    request({ url: `${EDIT_SUBFEATURE}${selectedRow}`, method: "put", data: formData, headers }).then(res => {

      handleClose(); //close the dialog after successful submission
      SubFeatureList()
    })
      .catch(error => {
        console.log(error);
      });
  }

  const handleAddSubmit = (data) => {

    const formData = new FormData();

    formData.append('image', file)
    formData.append('featuresOrder', false)
    formData.append('imgAlternative', data.imgAlternative.trim().replace(/\s{2,}/g, ' '));
    formData.append('topic', data.topic.trim().replace(/\s{2,}/g, ' '));

    const selectedCategory = FeatureData.find(item => item.title === data.category);
    const categoryId = selectedCategory ? selectedCategory.id : null;
    console.log(categoryId);

    formData.append('category', categoryId);
    // formData.append('category',data.category);
    descriptions.forEach((description, index) => {
      formData.append(`description[${index}]`, description.trim().replace(/\s{2,}/g, ' '));
    });

    if (!file) {
      alert('Please select a Image.');
      return;
    }
    // const minLength = 2;
    // const maxLength = 100;

    if (descriptions.length === 0) {
      const alertMessage = "Please provide description.";
      alert(alertMessage);
      return;
    }

    const invalidIndex = descriptions.findIndex((description) => !isValidDescription(description));

    if (invalidIndex !== -1) {
      const alertMessage = `Description ${invalidIndex + 1} ${getDescriptionLengthErrorMessage()}`;
      alert(alertMessage);
      return;
    }

    function isValidDescription(description) {
      const trimmedDescription = description.trim();
      return trimmedDescription !== "" && trimmedDescription.length >= 2 && trimmedDescription.length <= 100;
    }

    function getDescriptionLengthErrorMessage() {
      if (descriptions.some((description) => description.trim().length < 2)) {
        return `should be a minimum of 2 characters.`;
      } else if (descriptions.some((description) => description.trim().length > 100)) {
        return `cannot exceed 100 characters.`;
      }
      return "";
    }


    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    request({ url: ADD_SUBFEATURE, method: "post", data: formData, headers }).then(res => {
      console.log(res);
      if (res.status === 200) {
        handleAddClose();
        SubFeatureList();
        reset();
        setFile(null);
        setDescriptions([]);


      }
    }).catch(error => {
      console.log(error.response); // Log the error response object
      if (error.response) {
        // Request was made and server responded with a status code
        console.log("error.response.status" + error.response.status);
        console.log("fhgfhgf" + error.response.data.message);

        if (error.response.status !== 200 || error.response.status !== 201) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      } else if (error.request) {

        console.log(error.request);
      } else {

        console.log('Error', error.message);
      }
    });
  }


  // const handleSaveRow = ({ row, values, exitEditingMode }) => {
  //   const requiredFields = [ 'description','imgAlternative'];
  //   const missingFields = requiredFields.filter((field) => !values[field]);
  //   if (missingFields.length > 0) {
  //     alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
  //     return;
  //   }

  //   if (values.imgAlternative.length < 2) {
  //     alert("Please provide a minimum of 2 characters.");
  //     return;
  //   }
  //   if (values.imgAlternative.length > 250) {
  //     alert("Alternative Image Name can\'t exceed 250 characters");
  //     return;
  //   }
  //   const formData = new FormData();
  //   formData.append('description',values.description)
  //   formData.append('image',selectedFile)
  //   formData.append('featuresOrder',values.featuresOrder === 'Left' ? true : false)
  //   formData.append('imgAlternative',values.imgAlternative);
  //   const headers = {
  //     'Content-Type': 'multipart/form-data'
  //   };

  //   request({ url: `${EDIT_SUBFEATURE}${row.original.id}`, method: "put", data:formData, headers})
  //     .then((res) => {
  //       const updatedData = [...response];
  //       updatedData[row.index] = res.data;
  //       setResponse(updatedData);
  //       exitEditingMode();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };



  function handleDelete(data) {
    const isConfirmed = window.confirm('Are you sure you want to delete this feature card?');

    if (isConfirmed) {
      console.log(data.original.id);
      // console.log("This is delete inside");

      request({ url: `${DELETE_SUBFEATURE}${data.original.id}`, method: "delete" })
        .then(res => {
          // console.log(res);
          SubFeatureList();
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // console.log('Deletion canceled.');
    }
  }





  const handleClickOpen = () => {
    setOpen(true);

  };



  const handleAddClose = () => {
    setOpen(false);
    reset();
    setFile(null);
    setDescriptions([]);
  }
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };







  console.log('Feature data in state:', FeatureData);

  const columns = useMemo(
    (rowData) => {
      const getTitleById = (id) => {
        const feature = FeatureData.find((item) => item.id === id);
        return feature ? feature.title : 'Title not found';
      };
  
    
    return[
      {
        accessorKey: 'id',
        header: 'Id',
        enableHiding: false
      },

      {

        accessorFn: (row) => striptags(row.topic),
        header: 'Topic',
        accessorKey: 'topic'

      },
      {

        accessorFn: (row) => striptags(getTitleById(parseInt(row.category))),
        header: 'Category',
        accessorKey: 'category'

      },
      {
        accessorFn: (row) => {
          // Assuming row.descriptionList is an array
          if (Array.isArray(row.descriptionList)) {
            return row.descriptionList.map(item => item.content).join(', ');
          } else {
            return ''; // Handle the case when descriptionList is not an array
          }
        },
        header: 'Description',
        accessorKey: 'description'
      },

      {
        accessorKey: 'imgAlternative',
        header: 'Alternative Image Name',
        accessorFn: (row) => row ? row.imgAlternative : ''
      },

      {
        accessorKey: 'imageUrl',
        header: 'Image',
        accessorFn: (row) => row ? row.imageUrl : '',
        muiTableBodyCellEditTextFieldProps: {
          type: 'file',
          value: null,
          onChange: handleFileChange

        },
        render: (row) => {
          return (
            <div>

              {selectedFileName}
            </div>
          );
        }
      },


    ];
    },
    [striptags,selectedFileName,FeatureData]
  );
  return (

    <div className="mt-3">
      <Button variant="contained" style={{ float: 'left', marginTop: 20, marginLeft: 20 }} onClick={handleClickOpen}>
        Add
      </Button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{ width: "100%" }}
      >
        <MuiAlert severity="error" onClose={handleSnackbarClose} style={{ width: '50%' }}>
          {errorMessage}
        </MuiAlert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title" fullWidth
        maxWidth="md">
        <DialogTitle id="draggable-dialog-title">ADD</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleSubmit(handleAddSubmit)}>




            <br />
            <Controller
              name="topic"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: 'Topic is required' },
                validate: {
                  minLength: value => value.trim().length >= 2 || 'Please provide a minimum of 2 characters.',
                  maxLength: value => value.trim().length <= 30 || 'Topic cannot exceed 30 characters.',
                  noWhitespace: value => value.trim() !== '' || 'Topic is required',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="Topic"
                  margin='dense'
                  error={Boolean(errors?.topic?.message)}
                  helperText={errors?.topic?.message}
                />
              )}
            />



            <div>
              <Button variant="contained" style={{ marginTop: 20 }} onClick={handleAddDescription}>
                Add Description
              </Button>

              {descriptions.map((description, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label={`Description ${index + 1}`}
                    margin="dense"
                    value={description}
                    onChange={(e) => {
                      const newDescriptions = [...descriptions];
                      newDescriptions[index] = e.target.value;
                      setDescriptions(newDescriptions);
                    }}
                  />

                  {/* Delete button for each description */}
                  <IconButton color="error" onClick={() => handleDeleteDescription(index)} aria-label="delete">
                    <Delete />
                  </IconButton>
                </div>
              ))}
            </div>
            <br />

            <Controller
              name="category"
              control={control}
              defaultValue={""}
              rules={{
                validate: (value) => value !== "" || 'category is required'
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)}>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Category"
                    value={field.value} // Set the value manually
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedOption = FeatureData.find(item => item.title === selectedValue);
                      console.log('Selected ID:', selectedOption ? selectedOption.id : null);
                      console.log('Selected Option:', selectedValue);
                      field.onChange(e); // Trigger the onChange function from the field
                    }}
                  >
                    {/* Dynamically generate MenuItem components based on the fetched data */}
                    {FeatureData.map(item => (
                      <MenuItem key={item.id} value={item.title}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>

                  {error && <FormHelperText style={{ color: 'red' }}>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />


            <Controller
              name="imgAlternative"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: 'Alternative Image Name  is required' },
                validate: {
                  minLength: value => value.trim().length >= 2 || 'Please provide a minimum of 2 characters.',
                  maxLength: value => value.trim().length <= 30 || 'Alternative Image Name  cannot exceed 30 characters.',
                  noWhitespace: value => value.trim() !== '' || 'Alternative Image Name  is required',
                },
                // pattern: {
                //   value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
                //   message: 'Topic should contain only alphabetic characters',
                // },
                // minLength: { value: 2, message: 'Please provide a minimum of 2 characters.' },
                // maxLength: { value: 250, message: "'Alternative Image Name can't exceed 250 characters.'" },
              }}
              // validate={value => value.trim()}
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="AlterNative Image"
                  margin='dense'
                  error={Boolean(errors?.imgAlternative?.message)}
                  helperText={errors?.imgAlternative?.message}

                />
              )}
            />
            <br></br>
            <h4>Upload Image:</h4>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <div {...getRootProps()}>
                    <p>Drag and drop your file here, or click to select a file</p>
                  </div>
                  <input {...getInputProps()} />
                  {file && (
                    <p>File uploaded: {file.name}</p>
                  )}
                </>
              )}
            </Dropzone>
            <DialogActions>
              <Button onClick={handleAddClose}>Cancel</Button>
              <Button type="submit" color="primary" >
                Submit
              </Button>
            </DialogActions>
          </Form>

        </DialogContent>

      </Dialog>
      <MaterialReactTable
        columns={columns}
        data={response}
        editingMode="row"
        //  enableEditing
        //  initialState={{ columnVisibility: { id: false } }}
        //  enableRowActions
        //  enableColumnFilters={false}

        //  onEditingRowSave={handleSaveRow}

        renderRowActionMenuItems={({ row }) => [
          <>

            <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
            <IconButton color="error" onClick={() => handleDelete(row)} >
              <Delete />  &nbsp;&nbsp;&nbsp;<Typography> Delete</Typography>
            </IconButton>
          </>

        ]}
        initialState={{ columnVisibility: { id: false } }}
        enableRowActions
        enableColumnFilters={false}

      />

      <Dialog open={selectedRowId !== null} onClose={handleClose} fullWidth
        maxWidth="md">
        <DialogTitle>Edit Row </DialogTitle>
        <DialogContent>
          <Form>

            <br />
            {/* <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Features Order</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={featuresOrder}
    label="Features Order"
    onChange={(e) => setFeaturesOrder(e.target.value)}
  >
    <MenuItem value={true}>Left</MenuItem>
    <MenuItem value={false}>Right</MenuItem>
   
  </Select>
</FormControl> */}
            <br />


            <TextField
              fullWidth
              label="Topic"
              margin="dense"
              value={edittopic}
              onChange={(e) => setEdittopic(e.target.value)} />




            <div>
              <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={handleAddTextBox}>
                Add Description
              </Button>
              {descriptionTextBoxes.map((content, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label={`Description ${index + 1}`}
                    style={{ flex: 1, marginRight: 10, marginTop: 10 }}
                    margin="dense"
                    value={content}
                    onChange={(e) => handleDescriptionChange(e.target.value, index)}
                  />

                  <IconButton color="error" onClick={() => handleRemoveTextBox(index)}>
                    <Delete />
                  </IconButton>

                </div>
              ))}
            </div>
            {/* 
<TextField
              fullWidth
              label="Category"
              margin="dense"
              value={editcat}
              onChange={(e) => setEditcat(e.target.value)}/> */}

            <br />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={editcat}
                label="Category"
                onChange={(e) => {
                  const selectedTitle = e.target.value; // This is the selected title
                  const selectedId = FeatureData.find(item => item.title === selectedTitle)?.id || '';

                  console.log('Selected ID:', selectedId);
                  console.log('Selected Title:', selectedTitle);

                  setEditcat(selectedTitle);  // Set the title as the state value
                }}
              >
                {FeatureData.map(item => (
                  <MenuItem key={item.id} value={item.title}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>







            <TextField
              fullWidth
              label="Alternative Image Name"
              margin="dense"
              value={editAlts}
              onChange={(e) => setEditAlt(e.target.value)} />


            <Dropzone onDrop={onDropEdit}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <div {...getRootProps()}>
                    <p>Drag and drop your file here, or click to select a file</p>
                  </div>
                  <input {...getInputProps()} />
                  {fileEdit && (
                    <p>File uploaded: {fileEdit.name}</p>
                  )}
                </>
              )}
            </Dropzone>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEdits}>Save</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default SubFeature